import React, { createContext, useReducer, useState } from 'react';
import VehicleBatteryReducer from './VehicleBatteryReducer';
import * as Constants from '../../Constants';

const initialState = {
    loading: true,
    success: false,
    fromOrigin: false,
    distributor: {},
    sales_branch: {},
    client: {},
    facility: {},
    mode: 'vehicle',
    vehicle: [],
    battery: [],
    batteryInfo: [],
    chargerInfo: [],
    batteryOpts: [],
    thermLocOptions: [
        { id: 0, name: 'セル間' }, // Between cells
        { id: 1, name: '接続幹' }, // Connection trunk
        { id: 2, name: '電池上' } // Battery
    ],
    sensor: [],
    editData: [],
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'id',
    sortOrder: 'asc',
    action: [{}],
    error: [{}]
};

export const VehicleBatteryContext = createContext(initialState);

export const VehicleBatteryProvider = ({ children }) => {
    const [state, dispatch] = useReducer(VehicleBatteryReducer, initialState);

    /* Basic hooks */
    const [clientRoute, setClientRoute] = useState('/');
    const [facilityRoute, setFacilityRoute] = useState('/');
    const [path, setPath] = useState('/');
    const [gateway, setGateway] = useState('/');
    const [ID, setID] = useState(null);
    const [showGraph, setShowGraph] = useState(false);

    /* Vehicle Hooks */
    const [vehicleNo, setVehicleNo] = useState('');
    const [frameNo, setFrameNo] = useState('');
    const [model, setModel] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [specialType, setSpecialType] = useState('');
    const [attachment, setAttachment] = useState('');
    const [battRepOp, setBattRepOp] = useState('');
    const [remarks, setRemarks] = useState('');
    const [batteryID, setBatteryID] = useState('');

    /* Battery hooks */
    const [batteryNo, setBatteryNo] = useState('');
    const [startTime, setStartTime] = useState('');
    const [batteryInfoID, setBatteryInfoID] = useState('');
    const [batteryData, setBatteryData] = useState('');
    const [chargerInfoID, setChargerInfoID] = useState('');
    const [chargerData, setChargerData] = useState('');
    const [thermLoc, setThermLoc] = useState('');
    const [thermLocData, setThermLocData] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [purchaseAmount, setPurchaseAmount] = useState('');
    const [chargeEndVolt, setChargeEndVolt] = useState('');
    const [chargeEndCurr, setChargeEndCurr] = useState('');
    const [alertTarget, setAlertTarget] = useState(false);
    const [maxVolt, setMaxVolt] = useState('');
    const [minVolt, setMinVolt] = useState('');
    const [maxTemp, setMaxTemp] = useState('');
    const [batteryLvl, setBatteryLvl] = useState('');
    const [liqSurface, setLiqSurface] = useState('');
    const [liqTempFixVal, setLiqTempFixVal] = useState('');
    const [tempCorrectionVal, setTempCorrectionVal] = useState('');
    const [usedCap, setUsedCap] = useState('');
    const [batteryProdModel, setBatteryProdModel] = useState('');
    const [sensorID, setSensorID] = useState('');
    const [sensorData, setSensorData] = useState('');

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };
    const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;
    const granular_permissions = localStorage.getItem('auth') === null ? [] : JSON.parse(localStorage.getItem('auth')).user_permissions;

    const actions = {
        viewVehicle: can('vehicle.view_vehicle'),
        createVehicle: can('vehicle.add_vehicle'),
        deleteVehicle: can('vehicle.delete_vehicle'),
        updateVehicle: can('vehicle.change_vehicle'),
        viewBattery: can('battery.view_battery'),
        createBattery: can('battery.add_battery'),
        deleteBattery: can('battery.delete_battery'),
        updateBattery: can('battery.change_battery')
    }

    async function sortData(name, direction) {
        let api_url = `${state.mode === 'vehicle' ? Constants.GET_VEHICLE_URL : Constants.GET_BATTERY_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}${state.facility ? `&facility=${state.facility.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (state.mode === 'vehicle') {
                payload_data = result.map((v, index) =>
                    [v.id, index + 1, v.vehicle_no, v.model, v.battery_no]);
            } else {
                payload_data = result.map((b, index) =>
                    [b.id, index + 1, b.battery_no, `${b.battery_product.maker} ${b.battery_product.nominal_voltage}V ${b.battery_product.nominal_capacity}Ah/5HR ${b.battery_product.battery_type.name}`, b.sensor]);
            }

            dispatch({
                type: state.mode === 'vehicle' ? 'SORT_TABLE_VEHICLE' : 'SORT_TABLE_BATTERY',
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${state.mode === 'vehicle' ? Constants.GET_VEHICLE_URL : Constants.GET_BATTERY_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${state.facility ? `&facility=${state.facility.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (state.mode === 'vehicle') {
                payload_data = result.map((v, index) =>
                    [v.id, index + 1, v.vehicle_no, v.model, v.battery_no]);
            } else {
                payload_data = result.map((b, index) =>
                    [b.id, index + 1, b.battery_no, `${b.battery_product.maker} ${b.battery_product.nominal_voltage}V ${b.battery_product.nominal_capacity}Ah/5HR ${b.battery_product.battery_type.name}`, b.sensor]);
            }

            dispatch({
                type: state.mode === 'vehicle' ? 'CHANGE_TABLE_NUM_ROWS_VEHICLE' : 'CHANGE_TABLE_NUM_ROWS_BATTERY',
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });

        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        let api_url = `${state.mode === 'vehicle' ? Constants.GET_VEHICLE_URL : Constants.GET_BATTERY_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.facility ? `&facility=${state.facility.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (state.mode === 'vehicle') {
                payload_data = result.map((v, index) =>
                    [v.id, index + 1, v.vehicle_no, v.model, v.battery_no]);
            } else {
                payload_data = result.map((b, index) =>
                    [b.id, index + 1, b.battery_no, `${b.battery_product.maker} ${b.battery_product.nominal_voltage}V ${b.battery_product.nominal_capacity}Ah/5HR ${b.battery_product.battery_type.name}`, b.sensor]);
            }

            dispatch({
                type: state.mode === 'vehicle' ? 'SET_TABLE_PAGE_NO_VEHICLE' : 'SET_TABLE_PAGE_NO_BATTERY',
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });

        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${state.mode === 'vehicle' ? Constants.GET_VEHICLE_URL : Constants.GET_BATTERY_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.facility ? `&facility=${state.facility.id}` : ''}`;
        api_url += query !== '' ? `&search=${query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (state.mode === 'vehicle') {
                payload_data = result.map((v, index) =>
                    [v.id, index + 1, v.vehicle_no, v.model, v.battery_no]);
            } else {
                payload_data = result.map((b, index) =>
                    [b.id, index + 1, b.battery_no, `${b.battery_product.maker} ${b.battery_product.nominal_voltage}V ${b.battery_product.nominal_capacity}Ah/5HR ${b.battery_product.battery_type.name}`, b.sensor]);
            }

            dispatch({
                type: state.mode === 'vehicle' ? 'QUERY_TABLE_DATA_VEHICLE' : 'QUERY_TABLE_DATA_BATTERY',
                query: query,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0
            });
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getData(id = null) {
        const sortName = state.sortName === 'id' ? (state.mode === 'vehicle' ? 'vehicle_no' : 'battery_no') : state.sortName;
        let api_url = `${state.mode === 'vehicle' ? Constants.GET_VEHICLE_URL : Constants.GET_BATTERY_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${sortName}&page_size=${state.rowsPerPage}${id ? `&facility=${id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (state.mode === 'vehicle') {
                payload_data = result.map((v, index) =>
                    [v.id, index + 1, v.vehicle_no, v.model, v.battery_no, v.battery_id]);
            } else {
                payload_data = result.map((b, index) =>
                    [b.id, index + 1, b.battery_no, `${b.battery_product.maker} ${b.battery_product.nominal_voltage}V ${b.battery_product.nominal_capacity}Ah/5HR ${b.battery_product.battery_type.name}`, b.sensor]);
            }
            dispatch({
                ...{ actions: actions },
                type: state.mode === 'vehicle' ? 'GET_VEHICLE' : 'GET_BATTERY',
                payload: payload_data,
                facility: res.data.body.data.facility,
                client: res.data.body.data.client,
                sales_branch: res.data.body.data.sales_branch,
                distributor: res.data.body.data.distributor,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getSearchSelectData(mode = '', id = null, unassigned = null, query = '', limit = 5, custom = {}) {
        let api_url = `${mode === 'battery' ? Constants.GET_BATTERY_URL : Constants.GET_SENSOR_URL}?order_by=-id${query ? `&search=${query}` : ''}&page_size=${limit}&${unassigned ? `unassigned=${unassigned}` : ''}${id ? `&facility=${id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const payload = res.data.body.data.results.map(i => {
                return { label: i[`${mode}_no`], value: i.id }
            });

            dispatch({
                type: `QUERY_${mode.toUpperCase()}_LIST`,
                payload: payload,
                query: query
            });

            return Promise.resolve(payload);
        } catch { }
    }

    async function getBatteryChargerList(id = null, unassigned = false) {
        let api_url = `${Constants.GET_BATTERY_CHARGER_URL}?paginate=false&order_by=maker&unassigned=${unassigned}${id ? `&facility=${id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'GET_BATTERY_CHARGER_LIST',
                payload: res.data.body.data
            });
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function getBatteryProductList(id = null, unassigned = false) {
        let api_url = `${Constants.GET_BATTERY_PRODUCT_MODEL_URL}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'GET_BATTERY_PRODUCT_LIST',
                payload: res.data
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function viewVehicle(id) {
        let api_url = `${Constants.GET_VEHICLE_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            dispatch({
                ...{ actions: actions },
                type: 'SET_ACTION',
            });

            setID(data.id);
            setVehicleNo(data.vehicle_no);
            setFrameNo(data.frame_no);
            setModel(data.model);
            setDeliveryDate(data.delivery_date);
            setSpecialType(data.special_type);
            setAttachment(data.attachment);
            setBattRepOp(data.battery_replacement_operation);
            setRemarks(data.remarks);
            if (data.battery) {
                setBatteryID(data.battery.id);
                setBatteryNo(data.battery.battery_no);
            }

            return Promise.resolve(res);
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function viewBattery(id) {
        let api_url = `${Constants.GET_BATTERY_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;
            let selectedThermLoc = null;

            /* This line of code is for looping the Thermometer locations static options declared in the initial state.
             * And get the equivalent text of data we got from API response `data.therm_m_point` */
            Object.entries(state.thermLocOptions).map(([key, value]) => {
                if (parseInt(data.therm_m_point) === value.id) {
                    selectedThermLoc = value.name;
                }

                return null;
            });
            dispatch({
                ...{ actions: actions },
                type: 'SET_ACTION',
            });

            setID(data.id);
            setBatteryNo(data.battery_no);
            setStartTime(data.opening_time);
            setBatteryInfoID(data.battery_product.id);
            setBatteryData(`${data.battery_product.maker} ${data.battery_product.nominal_voltage}V ${data.battery_product.nominal_capacity}Ah/5HR ${data.battery_product.battery_type.name}`);
            setBatteryProdModel(data.battery_product.model);
            setChargerInfoID(data.battery_charger.id);
            setChargerData(`${data.battery_charger.maker} ${data.battery_charger.charger_type.name} ${data.battery_charger.quick === 1 ? '急速充電' : '普通充電'}`);

            setThermLoc(data.therm_m_point);
            setThermLocData(selectedThermLoc);

            setPurchaseDate(data.purchase_date);
            setPurchaseAmount(data.price);
            setChargeEndVolt(data.vcf_off);
            setChargeEndCurr(data.icf_off);
            setAlertTarget(data.alert_target);
            setMaxVolt(data.alt_high_volt);
            setMinVolt(data.alt_low_volt);
            setMaxTemp(data.max_temp);
            setBatteryLvl(data.battery_level);
            setLiqSurface(data.liquid_surface);
            setLiqTempFixVal(data.temp_fixed_value);
            setTempCorrectionVal(data.temp_correction_value);
            setUsedCap(data.used_cap);
            if (data.sensor) {
                setSensorID(data.sensor.id);
                setSensorData(data.sensor.sensor_no);
            }

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function addVehicle(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_VEHICLE_URL, data, config);

            dispatch({
                type: 'ADD_VEHICLE',
                payload: [],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function updateVehicle(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_VEHICLE_URL}${record.id}`, record, config);

            dispatch({
                type: 'UPDATE_VEHICLE',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function deleteVehicle(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_VEHICLE_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_VEHICLE',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function addBattery(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_BATTERY_URL, data, config);

            dispatch({
                type: 'ADD_BATTERY',
                payload: [],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function updateBattery(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_BATTERY_URL}${record.id}`, record, config);

            dispatch({
                type: 'UPDATE_BATTERY',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function deleteBattery(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_BATTERY_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_BATTERY',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function viewParent(id) {
        let api_url = `${Constants.GET_FAC_DEPT_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            dispatch({
                type: 'VIEW_PARENT',
                distributor: data.distributor,
                sales_branch: data.sales_branch,
                client: data.client,
                facility: {
                    id: data.id,
                    name: data.name
                }
            });

            // id for route of  client should be the id of sales branch because to view client list, you need the ID of sales branch
            setClientRoute(`${getRoute('client')}${data.client.id}`);
            // id for route of  facility should be the id of client to view facility list, you need the ID of client
            setFacilityRoute(`${getRoute('facility')}${data.id}`);
        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function viewData(id) {
        let api_url = `${state.mode === 'vehicle' ? Constants.GET_VEHICLE_URL : Constants.GET_BATTERY_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            dispatch({
                type: state.mode === 'vehicle' ? 'VIEW_VEHICLE' : 'VIEW_BATTERY',
                payload: data
            });

        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeMode() {
        const sortName = state.mode === 'battery' ? 'vehicle_no' : 'battery_no';
        let api_url = `${state.mode === 'vehicle' ? Constants.GET_BATTERY_URL : Constants.GET_VEHICLE_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${sortName}&page_size=${state.rowsPerPage}${state.facility ? `&facility=${state.facility.id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (state.mode === 'vehicle') {
                payload_data = result.map((b, index) =>
                    [b.id, index + 1, b.battery_no, `${b.battery_product.maker} ${b.battery_product.nominal_voltage}V ${b.battery_product.nominal_capacity}Ah/5HR ${b.battery_product.battery_type.name}`, b.sensor]);
            } else {
                payload_data = result.map((v, index) =>
                    [v.id, index + 1, v.vehicle_no, v.model, v.battery_no]);
            }

            dispatch({
                type: 'CHANGE_MODE',
                payload: state.mode === 'vehicle' ? 'battery' : 'vehicle',
                battery: state.mode === 'vehicle' ? payload_data : [],
                vehicle: state.mode === 'battery' ? payload_data : [],
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    function openFromOrigin() {
        dispatch({
            type: 'FORM_OPEN_ORIGIN',
            payload: true
        });
    }

    async function downloadGraph(record) {
        try {
            const id = record.ids[0];
            const downloadConfig = Object.assign(config, { responseType: 'arraybuffer' });
            const res = await Constants.API_BASE_URL.get(
                `${Constants.GET_GRAPH_URL}?type=${record.type}&${record.type === 'battery' ? 'battery' : 'vehicle'}=${id}&start=${record.start}&end=${record.end}`,
                downloadConfig);

            return Promise.resolve(res);
        } catch (error) {
            console.log('err', error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error);
        }
    }

    async function download001(record) {
        try {
            const downloadConfig = Object.assign(config, { responseType: 'arraybuffer' });

            let url = `${Constants.GET_REPORT1_URL.replace("{client_id}", record.client_id)}/${record.file_type}?type=${record.type}&target_month=${record.target_month}`;
            if (record.ids) {
                record.ids.map((id) => {
                    url += `&${record.type === 'battery' ? 'battery' : 'vehicle'}=${id}`;
                })
                // url += `&${record.type === 'battery' ? 'battery' : 'vehicle'}=${record.id}`;
            }
            if (record.facility_id) {
                url += `&facility=${record.facility_id}`;
            }
            const res = await Constants.API_BASE_URL.get(
                url,
                downloadConfig);
            console.log(res);

            console.log('success');
            return Promise.resolve(res);
        } catch (error) {
            console.log('err', error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error);
        }
    }
    async function download002(record) {
        try {
            const downloadConfig = Object.assign(config, { responseType: 'arraybuffer' });
            const res = await Constants.API_BASE_URL.get(
                `${Constants.GET_REPORT2_URL.replace("{client_id}", record.client_id)}/${record.file_type}?type=${record.type}&${record.type === 'battery' ? 'battery' : 'vehicle'}=${record.id}&month=${record.month}`,
                downloadConfig);
            console.log(res);

            console.log('success');
            return Promise.resolve(res);
        } catch (error) {
            console.log('err', error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error);
        }
    }

    async function download003(record) {
        try {
            const downloadConfig = Object.assign(config, { responseType: 'arraybuffer' });
            const res = await Constants.API_BASE_URL.get(
                `${Constants.GET_REPORT3_URL}/${record.file_type}?type=${record.type}&${record.type === 'battery' ? 'battery' : 'vehicle'}=${record.id}&target_month=${record.target_month}`,
                downloadConfig);
            console.log(res);

            console.log('success');
            return Promise.resolve(res);
        } catch (error) {
            console.log('err', error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error);
        }
    }

    async function download004(record) {
        try {
            const downloadConfig = Object.assign(config, { responseType: 'arraybuffer' });
            const res = await Constants.API_BASE_URL.get(
                `${Constants.GET_REPORT4_URL}/${record.file_type}?type=${record.type}&${record.type === 'battery' ? 'battery' : 'vehicle'}=${record.id}&month=${record.month}`,
                downloadConfig);
            console.log(res);

            console.log('success');
            return Promise.resolve(res);
        } catch (error) {
            console.log('err', error);
            dispatch({
                type: 'VEHICLE_BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error);
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_VEHICLE_BATTERY_ERROR'
        });
    }

    // Generate path for general breadcrumbs for vehicle and battery
    function generatePath() {
        let path, gateway = null;

        switch (user_type) {
            case 'admin':
                path = '/distributor/sales_branch/client/facility/vehicle_battery';
                gateway = '/distributor/sales_branch/client/facility/car_battery';
                break;

            case 'distributor':
                path = '/sales_branch/client/facility/vehicle_battery';
                gateway = '/sales_branch/client/facility/car_battery';
                break;

            case 'sales_branch':
                path = '/client/facility/vehicle_battery';
                gateway = '/client/facility/car_battery';
                break;

            case 'client':
                path = '/facility/vehicle_battery';
                gateway = '/facility/car_battery';
                break;

            case 'facility':
                path = '/vehicle_battery';
                gateway = '/car_battery';
                break;

            default:
                path = '/';
                gateway = '/';
                break;
        }

        setGateway(gateway);
        setPath(path);
    }

    function getRoute(page_type) {
        let path = null;

        switch (user_type) {
            case 'admin':
                if (page_type === 'client') {
                    path = '/distributor/sales_branch/client/facility/';
                } else if (page_type === 'facility') {
                    path = '/distributor/sales_branch/client/facility/vehicle_battery/';
                }
                break;

            case 'distributor':
                if (page_type === 'client') {
                    path = '/sales_branch/client/facility/';
                } else if (page_type === 'facility') {
                    path = '/sales_branch/client/facility/vehicle_battery/';
                }
                break;

            default:
                path = '/';
                break;
        }

        return path;
    }

    function resetLocalStorage() {
        localStorage.clear();
        window.location.reload();
    }

    function can(action) {
        if (granular_permissions) {
            return granular_permissions.includes(action);
        }

        resetLocalStorage();
    }

    return (
        <VehicleBatteryContext.Provider value={{
            mode: state.mode,
            fromOrigin: state.fromOrigin,
            distributor: state.distributor,
            sales_branch: state.sales_branch,
            client: state.client,
            facility: state.facility,
            vehicle: state.vehicle,
            battery: state.battery,
            batteryInfo: state.batteryInfo,
            chargerInfo: state.chargerInfo,
            batteryOpts: state.batteryOpts,
            thermLocOptions: state.thermLocOptions,
            sensor: state.sensor,
            editData: state.editData,
            loading: state.loading,
            links: state.links,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            error: state.error,
            action: state.action,
            gateway, path, generatePath,
            ID, setID,
            clientRoute, facilityRoute,
            /* Vehicle */
            vehicleNo, setVehicleNo,
            frameNo, setFrameNo,
            model, setModel,
            deliveryDate, setDeliveryDate,
            specialType, setSpecialType,
            attachment, setAttachment,
            battRepOp, setBattRepOp,
            remarks, setRemarks,
            batteryID, setBatteryID,
            /* Battery*/
            batteryNo, setBatteryNo,
            startTime, setStartTime,
            batteryInfoID, setBatteryInfoID, batteryData,
            chargerInfoID, setChargerInfoID, chargerData,
            thermLoc, setThermLoc, thermLocData,
            purchaseDate, setPurchaseDate,
            purchaseAmount, setPurchaseAmount,
            chargeEndVolt, setChargeEndVolt,
            chargeEndCurr, setChargeEndCurr,
            alertTarget, setAlertTarget,
            maxVolt, setMaxVolt,
            minVolt, setMinVolt,
            maxTemp, setMaxTemp,
            batteryLvl, setBatteryLvl,
            liqSurface, setLiqSurface,
            liqTempFixVal, setLiqTempFixVal,
            tempCorrectionVal, setTempCorrectionVal,
            usedCap, setUsedCap,
            batteryProdModel, setBatteryProdModel,
            showGraph, setShowGraph,
            sensorID, setSensorID, sensorData, setSensorData,
            addVehicle, viewVehicle, updateVehicle, deleteVehicle,
            addBattery, viewBattery, updateBattery, deleteBattery,
            downloadGraph,
            download001,
            download002,
            download003,
            download004,
            getData, viewData, sortData, queryData,
            getSearchSelectData,
            getBatteryChargerList,
            getBatteryProductList,
            changeNumberOfRows,
            changePageNumber,
            openFromOrigin,
            viewParent,
            clearErrors,
            changeMode
        }}>
            {children}
        </VehicleBatteryContext.Provider>
    );
};