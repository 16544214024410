import React, { Suspense, lazy } from 'react';
import './App.css';

/* ROUTING IMPORTS */
import ProtectedRoute from './ProtectedRoute';
import { CircularProgress } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/* CONTEXT PROVIDERS */
import { AuthProvider } from './context/AuthContext';
import { MasterProvider } from './context/vehicle-battery/MasterPageContext';
import { UserProvider } from './context/user/UserContext';
import { DistributorProvider } from './context/distributor/DistributorContext';
import { SalesProvider } from './context/sales_branch/SalesContext';
import { ClientProvider } from './context/client/ClientContext';
import { FacilityDeptProvider } from './context/facility/FacilityDeptContext';
import { AlertProvider } from './context/alert/AlertContext';
import { AlertDestinationProvider } from './context/alert/AlertDestinationContext';
import { VehicleBatteryProvider } from './context/vehicle-battery/VehicleBatteryContext';
import { BatteryTypePropProvider } from './context/vehicle-battery/BatteryTypePropContext';
import { BatteryProvider } from './context/battery/BatteryContext';

/* LAZY LOADED COMPONENTS */
const UserMenu = lazy(() => import('./components/UserMenu'));
const Login = lazy(() => import('./components/Login'));
const ForgotPass = lazy(() => import('./components/ForgotPass'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const Forbidden = lazy(() => import('./components/Forbidden'));
const Account = lazy(() => import('./components/user/Account'));
const UserManagement = lazy(() => import('./components/user/UserManagement'));
const AddUser = lazy(() => import('./components/user/addUser'));
const EditUser = lazy(() => import('./components/user/EditUser'));
const Admin = lazy(() => import('./components/distributor/Admin'));
const AddDistributor = lazy(() => import('./components/distributor/AddDistributor'));
const EditDistributor = lazy(() => import('./components/distributor/EditDistributor'));
const SalesBranch = lazy(() => import('./components/sales_branch/SalesBranch'));
const AddSalesBranch = lazy(() => import('./components/sales_branch/AddSalesBranch'));
const EditSalesBranch = lazy(() => import('./components/sales_branch/EditSalesBranch'));
const ClientList = lazy(() => import('./components/client/ClientList'));
const AddClient = lazy(() => import('./components/client/AddClient'));
const EditClient = lazy(() => import('./components/client/EditClient'));
const FacilityDepartment = lazy(() => import('./components/facility/FacilityDepartment'));
const AddFacility = lazy(() => import('./components/facility/AddFacility'));
const EditFacility = lazy(() => import('./components/facility/EditFacility'));
const AlertManagement = lazy(() => import('./components/alert/AlertManagement'));
const AlertList = lazy(() => import('./components/alert/AlertList'));
const VehicleAndBattery = lazy(() => import('./components/vehicle-battery/VehicleAndBattery'));
const DetailGateway = lazy(() => import('./components/vehicle-battery/DetailGateway'));
const AddVehicle = lazy(() => import('./components/vehicle-battery/AddVehicle'));
const EditVehicle = lazy(() => import('./components/vehicle-battery/EditVehicle'));
const AddBattery = lazy(() => import('./components/vehicle-battery/AddBattery'));
const EditBattery = lazy(() => import('./components/vehicle-battery/EditBattery'));
const MasterManagement = lazy(() => import('./components/vehicle-battery/MasterManagement'));
const BatteryProps = lazy(() => import('./components/vehicle-battery/BatteryProps'));
const BatteryTypeProps = lazy(() => import('./components/vehicle-battery/BatteryTypeProps'));
const AddBatteryData = lazy(() => import('./components/vehicle-battery/AddBatteryData'));
const EditBatteryData = lazy(() => import('./components/vehicle-battery/EditBatteryData'));
const BatteryList = lazy(() => import('./components/battery/BatteryList'));

const loading = () => {
    return (
        <CircularProgress
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%'
            }} />
    );
}

function App() {
    return (
        <React.Fragment>
            <Router>
                <AuthProvider>
                    <Suspense fallback={loading()}>
                        <UserMenu />
                        <Switch>
                            <Route exact path='/' component={Login} />
                            <Route exact path='/user/password-reset-confirm/:uuid/:token' component={ForgotPass} />
                            <ProtectedRoute path='/NotFound' component={PageNotFound} />
                            <ProtectedRoute path='/Forbidden' component={Forbidden} />
                        </Switch>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <MasterProvider>
                            <BatteryTypePropProvider>
                                <Switch>
                                    <ProtectedRoute exact path='/master_management/' component={MasterManagement} />
                                    <ProtectedRoute exact path='/master_management/product/:prod_type/' component={BatteryProps} />
                                    <ProtectedRoute exact path='/master_management/product/:prod_type/add' component={AddBatteryData} />
                                    <ProtectedRoute exact path='/master_management/product/:prod_type/edit/:prod_id' component={EditBatteryData} />
                                    <ProtectedRoute exact path='/master_management/product/:prod_type/show/:prod_id' render={props => <EditBatteryData action='show' {...props} />} />
                                    <ProtectedRoute exact path='/master_management/battery/:prop_type' component={BatteryTypeProps} />
                                </Switch>
                            </BatteryTypePropProvider>
                        </MasterProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <BatteryProvider>
                            <Switch>
                                <ProtectedRoute exact path='/all_battery/' component={BatteryList} />
                            </Switch>
                        </BatteryProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <UserProvider>
                            <Switch>
                                {/*
                                -- Admin: Can manage all users
                                -- Distributor (Admin): Can manage distributor users (same level), sales branch, client, facility users
                                -- Distributor (Staff): Can manage distributor users (same level), sales branch, client, facility users
                                -- Sales Branch (Staff): Can manage sales branch users (same level), client, facility users
                                -- Client (Staff): Can manage client users (same level) and facility users */}
                                <ProtectedRoute exact path='/account' component={Account} />
                                <ProtectedRoute exact path='/:type/user_management/:id' component={UserManagement} />
                                <ProtectedRoute exact path='/:type/user_management/:id/addUser' component={AddUser} />
                                <ProtectedRoute exact path='/:type/user_management/:id/editUser/:user_id' component={EditUser} />
                                <ProtectedRoute exact path='/:type/user_management/:id/showUser/:user_id' render={props => <EditUser mode='show' {...props} />} />
                            </Switch>
                        </UserProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <VehicleBatteryProvider>
                            <Switch>
                                {/* Super admin
                                    */}
                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/addVehicle/:facility_id' component={AddVehicle} />
                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/editVehicle/:vehicle_id' component={EditVehicle} />

                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/addBattery/:facility_id' component={AddBattery} />
                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/editBattery/:battery_id' component={EditBattery} />

                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/showVehicle/:vehicle_id' render={props => <EditVehicle mode='show' {...props} />} />
                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/showBattery/:battery_id' render={props => <EditBattery mode='show' {...props} />} />
                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/car_battery/:facility_id' component={DetailGateway} />
                                <ProtectedRoute exact path='/distributor/sales_branch/client/facility/vehicle_battery/:facility_id' component={VehicleAndBattery} />

                                {/* Distributor admin
                                    */}
                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/addVehicle/:facility_id' component={AddVehicle} />
                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/editVehicle/:vehicle_id' component={EditVehicle} />

                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/addBattery/:facility_id' component={AddBattery} />
                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/editBattery/:battery_id' component={EditBattery} />

                                {/*
                                    Distributor admin
                                    Distributor staff */}
                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/showVehicle/:vehicle_id' render={props => <EditVehicle mode='show' {...props} />} />
                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/showBattery/:battery_id' render={props => <EditBattery mode='show' {...props} />} />
                                <ProtectedRoute exact path='/sales_branch/client/facility/vehicle_battery/:facility_id' component={VehicleAndBattery} />

                                {/* Sales branch staff
                                    */}
                                <ProtectedRoute exact path='/client/facility/vehicle_battery/showVehicle/:vehicle_id' render={props => <EditVehicle mode='show' {...props} />} />
                                <ProtectedRoute exact path='/client/facility/vehicle_battery/showBattery/:battery_id' render={props => <EditBattery mode='show' {...props} />} />
                                <ProtectedRoute exact path='/client/facility/vehicle_battery/:facility_id' component={VehicleAndBattery} />

                                {/* Client staff
                                    */}
                                <ProtectedRoute exact path='/facility/vehicle_battery/showVehicle/:vehicle_id' render={props => <EditVehicle mode='show' {...props} />} />
                                <ProtectedRoute exact path='/facility/vehicle_battery/showBattery/:battery_id' render={props => <EditBattery mode='show' {...props} />} />
                                <ProtectedRoute exact path='/facility/vehicle_battery/:facility_id' component={VehicleAndBattery} />

                                {/* Facility staff
                                */}
                                <ProtectedRoute exact path='/vehicle_battery/showVehicle/:vehicle_id' render={props => <EditVehicle mode='show' {...props} />} />
                                <ProtectedRoute exact path='/vehicle_battery/showBattery/:battery_id' render={props => <EditBattery mode='show' {...props} />} />

                                {/*
                                    Super admin
                                    Distributor admin
                                    Distributor staff
                                    Sales branch staff
                                    Client staff
                                    Facility staff
                                    ( This specific route with /vehicle-battery/:id is important for user management re-routing back to main route,
                                    So, for example: from user management, you want to go back to main route which is list of facilities.
                                    In the logic of user management, they always provide an ID, so when routing back we have a route to catch that exact path `/vehicle-battery/:id'`.
                                    This is just to prevent 404 page not found)
                                    */}
                                <ProtectedRoute exact path='/vehicle_battery/:facility_id' component={VehicleAndBattery} />
                                <ProtectedRoute exact path='/vehicle_battery/' component={VehicleAndBattery} />
                            </Switch>
                        </VehicleBatteryProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <AlertProvider>
                            <AlertDestinationProvider>
                                <ClientProvider>
                                    <FacilityDeptProvider>
                                        <Switch>
                                            <ProtectedRoute exact path='/distributor/sales_branch/client/facility/alert_management/:id' component={AlertManagement} />
                                            <ProtectedRoute exact path='/sales_branch/client/facility/alert_management/:id' component={AlertManagement} />
                                            <ProtectedRoute exact path='/client/facility/alert_management/:id' component={AlertManagement} />
                                            <ProtectedRoute exact path='/facility/alert_management/:id' component={AlertManagement} />
                                            <ProtectedRoute exact path='/alert_list/' component={AlertList} />
                                        </Switch>
                                    </FacilityDeptProvider>
                                </ClientProvider>
                            </AlertDestinationProvider>
                        </AlertProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <FacilityDeptProvider>
                            <ClientProvider>
                                <Switch>
                                    {/* Super admin
                                    */}
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/facility/addFacility/:client_id' component={AddFacility} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/facility/editFacility/:id' component={EditFacility} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/facility/showFacility/:id' render={routeProps => <EditFacility mode="show" {...routeProps} />} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/facility/:id' component={FacilityDepartment} />

                                    {/* Distributor admin
                                    */}
                                    <ProtectedRoute exact path='/sales_branch/client/facility/addFacility/:client_id' component={AddFacility} />
                                    <ProtectedRoute exact path='/sales_branch/client/facility/editFacility/:id' component={EditFacility} />

                                    {/*
                                    Distributor admin
                                    Distributor staff */}
                                    <ProtectedRoute exact path='/sales_branch/client/facility/showFacility/:id' render={routeProps => <EditFacility mode="show" {...routeProps} />} />
                                    <ProtectedRoute exact path='/sales_branch/client/facility/:id' component={FacilityDepartment} />

                                    {/* Sales branch staff
                                    */}
                                    <ProtectedRoute exact path='/client/facility/:id' component={FacilityDepartment} />
                                    <ProtectedRoute exact path='/client/facility/showFacility/:id' render={routeProps => <EditFacility mode="show" {...routeProps} />} />

                                    {/* Client staff
                                    */}
                                    <ProtectedRoute exact path='/facility/showFacility/:id' render={routeProps => <EditFacility mode="show" {...routeProps} />} />

                                    {/*
                                    Super admin
                                    Distributor admin
                                    Distributor staff
                                    Sales branch staff
                                    Client staff
                                    ( This specific route with /facility/:id is important for user management re-routing back to main route,
                                    So, for example: from user management, you want to go back to main route which is list of facilities.
                                    In the logic of user management, they always provide an ID, so when routing back we have a route to catch that exact path `/facility/:id`.
                                    This is just to prevent 404 page not found)
                                    */}
                                    <ProtectedRoute exact path='/facility/:id' component={FacilityDepartment} />
                                    <ProtectedRoute exact path='/facility/' component={FacilityDepartment} />
                                </Switch>
                            </ClientProvider>
                        </FacilityDeptProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <SalesProvider>
                            <ClientProvider>
                                <Switch>
                                    { /* Super admin
                                    */ }
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/addClient/:sales_branch_id' component={AddClient} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/editClient/:id' component={EditClient} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/showClient/:id' render={routeProps => <EditClient mode="show" {...routeProps} />} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/client/:id' component={ClientList} />
                                    <ProtectedRoute exact path='/client/' component={ClientList} />

                                    { /* Distributor admin
                                    */ }
                                    <ProtectedRoute exact path='/sales_branch/client/addClient/:sales_branch_id' component={AddClient} />
                                    <ProtectedRoute exact path='/sales_branch/client/editClient/:id' component={EditClient} />

                                    {/*
                                    Distributor admin
                                    Distributor staff */}
                                    <ProtectedRoute exact path='/sales_branch/client/showClient/:id' render={routeProps => <EditClient mode="show" {...routeProps} />} />
                                    <ProtectedRoute exact path='/sales_branch/client/:id' component={ClientList} />

                                    {/*
                                    Sales branch staff */}
                                    <ProtectedRoute exact path='/client/showClient/:id' render={routeProps => <EditClient mode="show" {...routeProps} />} />

                                    { /*
                                        Super admin
                                        Distributor admin
                                        Distributor staff
                                        Sales branch staff
                                        ( This specific route with /client/:id is important for user management re-routing back to main route,
                                         So, for example: from user management, you want to go back to main route which is client list.
                                         In the logic of user management, they always provide an ID, so when routing back we have a route to catch that exact path `/client/:id`.
                                         This is just to prevent 404 page not found) */ }
                                    <ProtectedRoute exact path='/client/:id' component={ClientList} />
                                    <ProtectedRoute exact path='/client/' component={ClientList} />
                                </Switch>
                            </ClientProvider>
                        </SalesProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <DistributorProvider>
                            <SalesProvider>
                                <Switch>
                                    { /* Super admin
                                    */ }
                                    <ProtectedRoute exact path='/distributor/sales_branch/addSales/:distributor_id' component={AddSalesBranch} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/editSales/:id' component={EditSalesBranch} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/showSales/:id' render={routeProps => <EditSalesBranch mode="show" {...routeProps} />} />
                                    <ProtectedRoute exact path='/distributor/sales_branch/:distributor_id' component={SalesBranch} />

                                    { /* Distributor admin
                                    */ }
                                    <ProtectedRoute exact path='/sales_branch/addSales/:distributor_id' component={AddSalesBranch} />
                                    <ProtectedRoute exact path='/sales_branch/editSales/:id' component={EditSalesBranch} />

                                    {/*
                                    Distributor admin
                                    Distributor staff */}
                                    <ProtectedRoute exact path='/sales_branch/showSales/:id' render={routeProps => <EditSalesBranch mode="show" {...routeProps} />} />

                                    { /*
                                        Super admin
                                        Distributor admin
                                        Distributor staff
                                        ( This specific route with /sales_branch/:id is important for user management re-routing back to main route,
                                         So, for example: from user management, you wantimport BatteryList from './components/battery/BatteryList';
 to go back to main route which is sales branch list.
                                         In the logic of user management, they always provide an ID, so when routing back we have a route to catch that exact path `/sales_branch/:id`.
                                         This is just to prevent 404 page not found) */ }
                                    <ProtectedRoute exact path='/sales_branch/:id' component={SalesBranch} />
                                    <ProtectedRoute exact path='/sales_branch/' component={SalesBranch} />
                                </Switch>
                            </SalesProvider>
                        </DistributorProvider>
                    </Suspense>
                    <Suspense fallback={loading()}>
                        <DistributorProvider>
                            <Switch>
                                { /*
                                Super admin */ }
                                <ProtectedRoute exact path='/distributor/' component={Admin} />
                                <ProtectedRoute exact path='/distributor/add' component={AddDistributor} />
                                <ProtectedRoute exact path='/distributor/edit/:id' component={EditDistributor} />
                                <ProtectedRoute exact path='/distributor/show/:id' render={routeProps => <EditDistributor mode="show" {...routeProps} />} />
                            </Switch>
                        </DistributorProvider>
                    </Suspense>
                </AuthProvider>
            </Router>
        </React.Fragment>
    );
}

export default App;