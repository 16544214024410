import React, { createContext, useReducer, useState } from 'react';
import ClientReducer from './ClientReducer';
import * as Constants from '../../Constants';

const initialState = {
    distributor: [],
    sales_branch: [],
    client_list: [],
    editData: [],
    loading: true,
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'name_kana',
    sortOrder: 'asc',
    success: false,
    action: [{
        view: false,
        create: false,
        delete: false,
        update: false,
        management: false
    }],
    error: [{
        name: null,
        address: null,
        name_kana: null,
        postal_code: null,
        settlement: null,
        telephone_number: null,
        client: null
    }]
};

export const ClientContext = createContext(initialState);

export const ClientProvider = ({ children }) => {

    const [companyName, setCompanyName] = useState('');
    const [kanaName, setKanaName] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [settlement, setSettlement] = useState('');
    const [remark, setRemark] = useState('');
    const [path, setPath] = useState(null);

    const [state, dispatch] = useReducer(ClientReducer, initialState);

    const granular_permissions = localStorage.getItem('auth') === null ? [] : JSON.parse(localStorage.getItem('auth')).user_permissions;

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    // User permissions for crud operations
    const actions = {
        view: can('client.view_client'),
        create: can('client.add_client'),
        delete: can('client.delete_client'),
        update: can('client.change_client'),
        management: canManageUsers(),
    }

    async function getClientList(id = null) {
        let api_url = `${Constants.GET_CLIENT_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${id ? `&sales_branch=${id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                ...{ actions: actions },
                type: 'GET_CLIENT_LIST',
                payload: res.data.body.data.results.map((cl, index) =>
                    [cl.id, index + 1, cl.name, cl.number_of_batteries]),
                distributor: res.data.body.data.distributor,
                sales_branch: res.data.body.data.sales_branch,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getAllClientList(id = null) {
        let api_url = `${Constants.GET_CLIENT_URL}?paginate=false&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                ...{ actions: actions },
                type: 'GET_CLIENT_LIST',
                payload: res.data.body.data.map((cl, index) =>
                    [cl.id, index + 1, cl.name, cl.number_of_batteries])
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_CLIENT_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}${state.sales_branch ? `&sales_branch=${state.sales_branch.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SORT_TABLE',
                payload: res.data.body.data.results.map((cl, index) =>
                    [cl.id, index + 1, cl.name, cl.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_CLIENT_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${state.sales_branch ? `&sales_branch=${state.sales_branch.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'CHANGE_TABLE_NUM_ROWS',
                payload: res.data.body.data.results.map((cl, index) =>
                    [cl.id, index + 1, cl.name, cl.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_CLIENT_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.sales_branch ? `&sales_branch=${state.sales_branch.id}` : ''}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SET_TABLE_PAGE_NO',
                payload: res.data.body.data.results.map((cl, index) =>
                    [cl.id, index + 1, cl.name, cl.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_CLIENT_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.sales_branch ? `&sales_branch=${state.sales_branch.id}` : ''}`;

        try {
            api_url += query !== '' ? `&search=${query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'QUERY_TABLE_DATA',
                query: query,
                payload: res.data.body.data.results.map((cl, index) =>
                    [cl.id, index + 1, cl.name, cl.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0
            });
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function addClient(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_CLIENT_URL, data, config);

            dispatch({
                type: 'ADD_CLIENT',
                payload: [data.id, data.name, data.number_of_batteries],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function deleteClient(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_CLIENT_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_CLIENT',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function updateClient(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_CLIENT_URL}${state.editData.id}`, record, config);

            dispatch({
                type: 'UPDATE_CLIENT',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function viewClient(id) {
        let api_url = `${Constants.GET_CLIENT_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            setCompanyName(data.name);
            setKanaName(data.name_kana);
            setPostalCode(data.postal_code);
            setStreetAddress(data.address);
            setSettlement(data.financial_month);
            setPhoneNumber(data.telephone_number);
            setRemark(data.remarks);

            dispatch({
                type: 'VIEW_CLIENT',
                payload: data,
                distributor: data.distributor,
                sales_branch: data.sales_branch
            });

        } catch (error) {
            dispatch({
                type: 'CLIENT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_CLIENT_ERROR'
        });
    }

    // Create path generator base on user type if super admin, distributor admin, distributor staff or sales branch
    function generatePath() {
        let path = null;
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (user_type) {
            case 'admin':
                /*
                * /distributor/sales_branch/client/addClient
                * /distributor/sales_branch/client/editClient/:id
                * /distributor/sales_branch/client/showClient/:id
                * /distributor/sales_branch/client/client/:id
                * */
                path = '/distributor/sales_branch/client';
                break;

            case 'distributor':
                /*
                 * /sales_branch/client/addClient
                 * /sales_branch/client/editClient/:id
                 * /sales_branch/client/showClient/:id
                 * /sales_branch/client/:id
                 */
                path = '/sales_branch/client';
                break;

            case 'sales_branch':
                /*
                 * /client
                 * /client/showClient/:id
                 */
                path = '/client';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    function resetLocalStorage() {
        localStorage.clear();
        window.location.reload();
    }

    function can(action) {
        if (granular_permissions) {
            return granular_permissions.includes(action);
        }

        resetLocalStorage();
    }

    function canManageUsers() {
        if (granular_permissions) {
            return granular_permissions.includes('users.view_user')
                && granular_permissions.includes('users.add_user')
                && granular_permissions.includes('users.change_user')
                && granular_permissions.includes('users.delete_user');
        }

        resetLocalStorage();
    }

    return (
        <ClientContext.Provider value={{
            distributor: state.distributor,
            sales_branch: state.sales_branch,
            client_list: state.client_list,
            editData: state.editData,
            loading: state.loading,
            links: state.links,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            error: state.error,
            action: state.action,
            companyName, setCompanyName,
            kanaName, setKanaName,
            postalCode, setPostalCode,
            streetAddress, setStreetAddress,
            phoneNumber, setPhoneNumber,
            settlement, setSettlement,
            remark, setRemark,
            path, generatePath,
            sortData,
            changeNumberOfRows,
            changePageNumber,
            queryData,
            viewClient,
            addClient,
            deleteClient,
            updateClient,
            getClientList,
            getAllClientList,
            clearErrors
        }}>
            {children}
        </ClientContext.Provider>
    );
};