import React, { createContext, useReducer, useState } from 'react';
import DistributorReducer from './DistributorReducer';
import * as Constants from '../../Constants';

const initialState = {
    distributors: [],
    editData: [],
    loading: true,
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'name_kana',
    sortOrder: 'asc',
    success: false,
    error: [{
        name: null,
        address: null,
        name_kana: null,
        postal_code: null,
        telephone_number: null,
        distributor: null
    }]
};

export const DistributorContext = createContext(initialState);

export const DistributorProvider = ({ children }) => {

    const [companyName, setCompanyName] = useState('');
    const [kanaName, setKanaName] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [remark, setRemark] = useState('');

    const [state, dispatch] = useReducer(DistributorReducer, initialState);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_DISTRIBUTOR_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SORT_TABLE',
                payload: res.data.body.data.results.map((distributor, index) =>
                    [distributor.id, index + 1, distributor.name, distributor.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_DISTRIBUTOR_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'CHANGE_TABLE_NUM_ROWS',
                payload: res.data.body.data.results.map((distributor, index) =>
                    [distributor.id, index + 1, distributor.name, distributor.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_DISTRIBUTOR_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SET_TABLE_PAGE_NO',
                payload: res.data.body.data.results.map((distributor, index) =>
                    [distributor.id, index + 1, distributor.name, distributor.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_DISTRIBUTOR_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;

        try {
            api_url += query !== '' ? `&search=${query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'QUERY_TABLE_DATA',
                query: query,
                payload: res.data.body.data.results.map((distributor, index) =>
                    [distributor.id, index + 1, distributor.name, distributor.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0
            });
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getDistributors() {
        let api_url = `${Constants.GET_DISTRIBUTOR_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'GET_DISTRIBUTOR',
                payload: res.data.body.data.results.map((distributor, index) =>
                    [distributor.id, index + 1, distributor.name, distributor.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function viewDistributor(id) {
        let api_url = `${Constants.GET_DISTRIBUTOR_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data

            dispatch({
                type: 'VIEW_DISTRIBUTOR',
                payload: data
            });

            setCompanyName(data.name);
            setKanaName(data.name_kana);
            setPostalCode(data.postal_code);
            setStreetAddress(data.address);
            setPhoneNumber(data.telephone_number);
            setRemark(data.remarks);

        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function addDistributor(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_DISTRIBUTOR_URL, data, config);

            dispatch({
                type: 'ADD_DISTRIBUTOR',
                payload: [data.id, data.name, data.number_of_batteries],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function deleteDistributor(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_DISTRIBUTOR_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_DISTRIBUTOR',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function updateDistributor(distributor) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_DISTRIBUTOR_URL}${distributor.id}`, distributor, config);

            dispatch({
                type: 'UPDATE_DISTRIBUTOR',
                payload: distributor,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_DISTRIBUTOR_ERROR'
        });
    }

    return (
        <DistributorContext.Provider value={{
            distributors: state.distributors,
            editData: state.editData,
            loading: state.loading,
            links: state.links,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            error: state.error,
            companyName, setCompanyName,
            kanaName, setKanaName,
            postalCode, setPostalCode,
            streetAddress, setStreetAddress,
            phoneNumber, setPhoneNumber,
            remark, setRemark,
            sortData,
            queryData,
            changeNumberOfRows,
            changePageNumber,
            getDistributors,
            viewDistributor,
            addDistributor,
            deleteDistributor,
            updateDistributor,
            clearErrors
        }}>
            {children}
        </DistributorContext.Provider>
    );
};