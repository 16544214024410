export default (state, action) => {
    switch (action.type) {
        case 'GET_VEHICLE':
            return {
                ...state,
                loading: false,
                vehicle: action.payload,
                facility: action.facility,
                client: action.client,
                sales_branch: action.sales_branch,
                distributor: action.distributor,
                next: action.next,
                previous: action.previous,
                count: action.count,
                action: action.actions
            }

        case 'GET_BATTERY':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                facility: action.facility,
                client: action.client,
                sales_branch: action.sales_branch,
                distributor: action.distributor,
                next: action.next,
                previous: action.previous,
                count: action.count,
                action: action.actions
            }

        case 'SET_ACTION':
            return {
                ...state,
                loading: false,
                action: action.actions
            }

        case 'GET_BATTERY_PRODUCT_LIST':
            return {
                ...state,
                loading: false,
                batteryInfo: action.payload
            }

        case 'GET_BATTERY_CHARGER_LIST':
            return {
                ...state,
                loading: false,
                chargerInfo: action.payload
            }

        case 'GET_UNASSIGNED_BATTERY':
            return {
                ...state,
                loading: false,
                battery: action.payload
            }

        case 'VIEW_PARENT':
            return {
                ...state,
                distributor: action.distributor,
                sales_branch: action.sales_branch,
                facility: action.facility,
                client: action.client
            }

        case 'FORM_OPEN_ORIGIN':
            return {
                ...state,
                fromOrigin: action.payload
            }

        case 'SORT_TABLE_VEHICLE':
            return {
                ...state,
                loading: false,
                vehicle: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_BATTERY':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS_VEHICLE':
            return {
                ...state,
                loading: false,
                vehicle: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'CHANGE_TABLE_NUM_ROWS_BATTERY':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO_VEHICLE':
            return {
                ...state,
                loading: false,
                vehicle: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SET_TABLE_PAGE_NO_BATTERY':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA_VEHICLE':
            return {
                ...state,
                query: action.query,
                vehicle: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA_BATTERY':
            return {
                ...state,
                query: action.query,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_BATTERY_LIST':
            return {
                ...state,
                batteryOpts: action.payload,
                query: action.query
            }

        case 'QUERY_SENSOR_LIST':
            return {
                ...state,
                sensor: action.payload,
                query: action.query
            }

        case 'ADD_BATTERY':
            return {
                ...state,
                loading: false,
                battery: [...state.battery, action.payload],
                success: action.success,
                error: action.error
            }

        case 'UPDATE_BATTERY':
            return {
                ...state,
                loading: false,
                battery: [...state.battery, action.payload]
            }

        case 'DELETE_BATTERY':
            return {
                ...state,
                battery: state.battery.filter(b => b[0] !== action.payload)
            }

        case 'ADD_VEHICLE':
            return {
                ...state,
                loading: false,
                vehicle: [...state.vehicle, action.payload],
                success: action.success,
                error: action.error
            }

        case 'UPDATE_VEHICLE':
            return {
                ...state,
                loading: false,
                vehicle: [...state.vehicle, action.payload]
            }

        case 'DELETE_VEHICLE':
            return {
                ...state,
                vehicle: state.vehicle.filter(v => v[0] !== action.payload)
            }

        case 'VEHICLE_BATTERY_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CHANGE_MODE':
            return {
                ...state,
                mode: action.payload,
                battery: action.battery,
                vehicle: action.vehicle,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CLEAR_VEHICLE_BATTERY_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}