export default (state, action) => {
    switch (action.type) {
        case 'GET_DISTRIBUTOR':
            return {
                ...state,
                loading: false,
                distributors: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'VIEW_DISTRIBUTOR':
            return {
                ...state,
                loading: false,
                editData: action.payload
            }

        case 'SORT_TABLE':
            return {
                ...state,
                loading: false,
                distributors: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS':
            return {
                ...state,
                loading: false,
                distributors: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO':
            return {
                ...state,
                loading: false,
                distributors: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA':
            return {
                ...state,
                query: action.query,
                distributors: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'ADD_DISTRIBUTOR':
            return {
                ...state,
                loading: false,
                distributors: [...state.distributors, action.payload],
                success: action.success,
                error: action.error
            }

        case 'DELETE_DISTRIBUTOR':
            return {
                ...state,
                distributors: state.distributors.filter(distributor => distributor[0] !== action.payload)
            }

        case 'UPDATE_DISTRIBUTOR':
            return {
                ...state,
                loading: false,
                distributors: [...state.distributors, action.payload],
            }

        case 'DISTRIBUTOR_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_DISTRIBUTOR_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}