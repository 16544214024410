import React, { createContext, useReducer, useState } from 'react';
import BatteryReducer from './BatteryReducer';
import * as Constants from '../../Constants';

const initialState = {
    mode: null,
    loading: false,
    battery: [],
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'battery_no',
    sortOrder: 'asc',
    success: false,
    action: [{}],
    error: [{}]
};

export const BatteryContext = createContext(initialState);

export const BatteryProvider = ({ children }) => {

    const [path, setPath] = useState(null);

    const [state, dispatch] = useReducer(BatteryReducer, initialState);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    function getPayload(result) {
        let payload_data = [];

        return result.map((bt, index) => [bt.id, index + 1, bt.battery_no,
        bt.battery_product.model, `${bt.facility.client.name} ${bt.facility.name}`]);
    }

    async function getBatteryList(id = null) {
        let api_url = `${Constants.GET_BATTERY_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${id ? `&facility=${id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;

            dispatch({
                type: 'GET_BATTERY_LIST',
                payload: getPayload(result),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }
    async function setQuery(query = '') {
        dispatch({
            type: `SET_QUERY`,
            payload: query,
        });
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_BATTERY_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
        api_url += query !== undefined && query !== '' ? `&search=${query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `QUERY_TABLE_DATA_BATTERY_LIST`,
                page: 0,
                payload: payload_data,
                query: query,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            dispatch({
                type: 'BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_BATTERY_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SORT_TABLE_BATTERY_LIST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_BATTERY_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${state.facility ? `&client=${state.facility.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `CHANGE_TABLE_NUM_ROWS_BATTERY_LIST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
        } catch (error) {
            dispatch({
                type: 'BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_BATTERY_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.facility ? `&client=${state.facility.id}` : ''}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SET_TABLE_PAGE_NO_BATTERY_LIST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
        } catch (error) {
            dispatch({
                type: 'BATTERY_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function deleteBattery(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_BATTERY_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_BATTERY',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'BATTERY_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_BATTERY_ERROR'
        });
    }


    function generatePath() {
        let path = null;
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (user_type) {
            case 'admin':
                path = '/distributor/sales_branch/client/facility';
                break;

            case 'distributor':
                path = '/sales_branch/client/facility';
                break;

            case 'sales_branch':
                path = '/client/facility';
                break;

            case 'client':
                path = '/facility';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    return (
        <BatteryContext.Provider value={{
            error: state.error,
            loading: state.loading,
            battery: state.battery,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            action: state.action,
            query: state.query,
            getBatteryList,
            generatePath, path,
            queryData, sortData,
            setQuery,
            clearErrors,
            changeNumberOfRows,
            changePageNumber,
            deleteBattery
        }}>
            {children}
        </BatteryContext.Provider>
    );
};