export default (state, action) => {
    switch (action.type) {
        case 'LOAD_MASTER_PAGE_PERMISSION':
            return {
                ...state,
                action: action.payload
            }

        case 'CHANGE_MODE':
            return {
                ...state,
                mode: action.payload
            }

        case 'GET_BATTERY_PRODUCT_LIST':
            return {
                ...state,
                loading: false,
                mode: action.mode,
                sortName: action.sortName,
                batteryInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'GET_BATTERY_CHARGER_LIST':
            return {
                ...state,
                loading: false,
                mode: action.mode,
                sortName: action.sortName,
                chargerInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'GET_BATTERY_TYPE_LIST':
            return {
                ...state,
                sortName: action.sortName,
                batteryTypes: action.payload
            }

        case 'GET_CHARGER_TYPE_LIST':
            return {
                ...state,
                sortName: action.sortName,
                chargerTypes: action.payload
            }

        case 'ADD_BATTERY_PRODUCT':
            return {
                ...state,
                loading: false,
                batteryInfo: [...state.batteryInfo, action.payload],
                success: action.success,
                error: action.error
            }

        case 'ADD_BATTERY_CHARGER':
            return {
                ...state,
                loading: false,
                chargerInfo: [...state.chargerInfo, action.payload],
                success: action.success,
                error: action.error
            }

        case 'UPDATE_BATTERY_PRODUCT':
            return {
                ...state,
                loading: false,
                batteryInfo: [...state.batteryInfo, action.payload],
                success: action.success,
                error: action.error
            }

        case 'UPDATE_BATTERY_CHARGER':
            return {
                ...state,
                loading: false,
                chargerInfo: [...state.chargerInfo, action.payload],
                success: action.success,
                error: action.error
            }

        case 'DELETE_BATTERY_PRODUCT':
            return {
                ...state,
                batteryInfo: state.batteryInfo.filter(b => b[0] !== action.payload)
            }

        case 'DELETE_BATTERY_CHARGER':
            return {
                ...state,
                chargerInfo: state.chargerInfo.filter(b => b[0] !== action.payload)
            }

        case 'QUERY_TABLE_DATA_BATTERY_PRODUCT':
            return {
                ...state,
                query: action.query,
                batteryInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA_BATTERY_CHARGER':
            return {
                ...state,
                query: action.query,
                chargerInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SORT_TABLE_BATTERY_PRODUCT':
            return {
                ...state,
                loading: false,
                batteryInfo: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_BATTERY_CHARGER':
            return {
                ...state,
                loading: false,
                chargerInfo: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS_BATTERY_PRODUCT':
            return {
                ...state,
                loading: false,
                batteryInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'CHANGE_TABLE_NUM_ROWS_BATTERY_CHARGER':
            return {
                ...state,
                loading: false,
                chargerInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO_BATTERY_PRODUCT':
            return {
                ...state,
                loading: false,
                batteryInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SET_TABLE_PAGE_NO_BATTERY_CHARGER':
            return {
                ...state,
                loading: false,
                chargerInfo: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'MASTER_PAGE_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_MASTER_PAGE_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}