import { toast } from 'react-toastify';
import { API_BASE_URL } from '../Constants';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const ROUTE_EXCEPTIONS = [
    '/user/password-reset-confirm/',
    '/auth/login/'
];

const exception = (responseURL) => {
    let found = false;

    ROUTE_EXCEPTIONS.map(url => {
        if (responseURL.includes(url)) {
            found = true;
        }

        return 0;
    });

    return found;
}

API_BASE_URL.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        let errMsg;
        let errObj = err;
        if (err.request.responseType === "arraybuffer") {
            console.log(err);
            console.log('err1', err.response.data);
            // const fileReader = new FileReader();
            // const text = fileReader.readAsText(err.response.data)
            // const json = buffer_to_string(err.response.data);
            const errData = buffer_to_string(err.response.data);
            errMsg = errData.details.message;
            errObj.response.data = errData
            console.log('errObj', err.response);
        } else {
            if (err.response && err.response.data.details) {
                errMsg = err.response.data.details.message;
            }
        }


        switch (err.response.status) {
            case 401:
                if (!exception(err.response.config.url)) {
                    localStorage.clear();
                    window.location.reload();
                }
                break;

            case 403:
                window.location.href = '/Forbidden';
                break;

            case 404:
                toast.error(errMsg, { position: toast.POSITION.TOP_CENTER });
                break;

            default:
                break;
        }

        return Promise.reject(errObj);
    }
);

function buffer_to_string(buf) {
    const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));
    if (!text) return undefined;
    return JSON.parse(text);
}