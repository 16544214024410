import React, { createContext, useReducer, useState } from 'react';
import UserReducer from '../user/UserReducer';
import * as Constants from '../../Constants';

const initialState = {
    parent: [],
    users: [],
    editUser: [],
    type: null,
    loading: false,
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'id',
    sortOrder: 'asc',
    success: false,
    error: [{
        username: null,
        email: null,
        password: null
    }]
};

export const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [subEmail, setSubEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isAdmin, setAsAdmin] = useState(false);
    const [isStaff, setAsStaff] = useState(false);
    const [isActive, setAsActive] = useState(true);
    const [isSuperUser, setAsSuperUser] = useState(false);

    const [remarks, setRemarks] = useState('');
    const [path, setPath] = useState('');
    const [mainRoute, setMainRoute] = useState('');
    const [userType, setUserType] = useState('');

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_USER_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}${urls(userType)}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SORT_TABLE',
                payload: res.data.body.data.results.map(user => [user.id, user.username, user.email, user.is_admin]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_USER_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${urls(userType)}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'CHANGE_TABLE_NUM_ROWS',
                payload: res.data.body.data.results.map(user => [user.id, user.username, user.email, user.is_admin]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function viewParent(type, id) {
        let url = null;

        switch (type) {
            case 'sales_branch':
                url = Constants.GET_SALES_BRANCH_URL;
                break;

            case 'client':
                url = Constants.GET_CLIENT_URL;
                break;

            case 'facility':
                url = Constants.GET_FAC_DEPT_URL;
                break;

            default:
                url = Constants.GET_DISTRIBUTOR_URL;
                break;
        }

        try {
            const res = await Constants.API_BASE_URL.get(`${url}${id}`, config);
            const data = { type, ...res.data.body.data }

            createRoute(type, data);

            dispatch({
                type: 'VIEW_USER_PARENT',
                payload: data
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function viewUser(id) {
        let api_url = `${Constants.GET_USER_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            dispatch({
                type: 'VIEW_USER_DATA',
                payload: data
            });

            setUsername(data.username);
            setEmail(data.email);
            setSubEmail(data.sub_email);
            setAsAdmin(data.is_admin);
            setAsActive(data.is_active);
            setAsStaff(data.is_staff);
            setAsSuperUser(data.is_superuser);
            setRemarks(data.remarks);

        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function updateUser() {
        let user = {
            ...parents(state.parent.type),
            username,
            email,
            remarks,
            is_admin: isAdmin,
            is_active: isActive,
            is_superuser: isSuperUser,
            is_staff: isStaff
        }

        if (password !== '') {
            user = { password, ...user };
        }

        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_USER_URL}${state.editUser.id}`, user, config);

            dispatch({
                type: 'UPDATE_USER',
                payload: user,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {

            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function updateAccount(id) {
        if ((password !== '' || confirmPass !== '') && password !== confirmPass) {
            return;
        }

        let user = {
            username,
            email,
            ...(subEmail !== '' && { sub_email: subEmail }),
            ...(password !== '' && { password }),
            is_admin: isAdmin,
            is_active: isActive,
            is_superuser: isSuperUser,
            is_staff: isStaff
        }

        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_USER_URL}${id}`, user, config);

            dispatch({
                type: 'UPDATE_USER',
                payload: user,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    function parents(type) {
        let parent = {};

        if (type === 'distributor') {
            parent = {
                distributor: state.parent.id
            };
        } else if (type === 'sales_branch') {
            parent = {
                distributor: state.parent.distributor.id,
                sales_branch: state.parent.id
            };
        } else if (type === 'client') {
            parent = {
                distributor: state.parent.distributor.id,
                sales_branch: state.parent.sales_branch.id,
                client: state.parent.id
            };
        } else {
            parent = {
                distributor: state.parent.distributor.id,
                sales_branch: state.parent.sales_branch.id,
                client: state.parent.client.id,
                facility: state.parent.id
            };
        }

        return parent;
    }

    function urls(type) {
        let urls = '';

        for (const [key, value] of Object.entries(parents(type))) {
            urls += `&${key}=${value}`;
        }

        return urls;
    }

    // Create path generator base on user type, i.e: admin, distributor, sales_branch, client, facility
    // Because depending on the user type, the main route will be different
    // So that when a user click main link on the left most side of the User Management breadcrumbs
    // It will route to the correct route location
    // This function returns a path depending on your user type and page type
    function getPath(page_type) {
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;
        let path = '/';

        switch (user_type) {
            case 'admin': // Admin user type or super admin
                // Depending on user type the url is different
                // from distributor management to distributor list
                // And no other user type can access this list just admin
                // admin = /distributor
                // List of page type: distributor, sales_branch, client, facility
                if (page_type === 'distributor' || page_type === null) {
                    path = '/distributor/';
                } else if (page_type === 'sales_branch') {
                    path = '/distributor/sales_branch/';
                } else if (page_type === 'client') {
                    path = '/distributor/sales_branch/client/';
                } else if (page_type === 'facility') {
                    path = '/distributor/sales_branch/client/facility/';
                }
                break;

            case 'distributor': // distributor admin or staff
                // from sales branch management to sales branch list depending on the user type
                // admin = distributor/sales_branch
                // distributor admin = /sales_branch
                // distributor staff = /sales_branch
                if (page_type === 'sales_branch' || page_type === null) {
                    path = '/sales_branch/';
                } else if (page_type === 'client') {
                    path = '/sales_branch/client/';
                } else if (page_type === 'facility') {
                    path = '/sales_branch/client/facility/';
                } else if (page_type === 'distributor') {
                    path = '/';
                }
                break;

            case 'sales_branch':
                if (page_type === 'client' || page_type === null) {
                    path = '/client/';
                } else if (page_type === 'facility') {
                    path = '/client/facility/';
                }
                break;

            case 'client':
                path = '/facility/';
                break;

            case 'facility':
                path = '/vehicle_battery/';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
        return path;
    }

    function createRoute(type, data) {
        const path = getPath(type);
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (type) {
            case 'distributor': // this is admin
                if (user_type === type) {
                    setMainRoute(`${path}sales_branch`);
                } else {
                    setMainRoute(`${path}sales_branch/${data.id}`);
                }
                // Depending on user type the url is different
                // from distributor management to distributor list
                // And no other user type can access this list just admin
                // admin = /distributor
                break;

            case 'sales_branch':
                // from sales branch management to sales branch list depending on the user type
                // admin = distributor/sales_branch
                // distributor admin = /sales_branch
                // distributor staff = /sales_branch
                if (user_type === type) {
                    setMainRoute(`${path}client`);
                } else {
                    setMainRoute(`${path}client/${data.id}`);
                }
                break;

            case 'client':
                if (user_type === type) {
                    setMainRoute(`${path}facility`);
                } else {
                    setMainRoute(`${path}facility/${data.id}`);
                }
                // From client management to client list
                // admin = distributor/sales_branch/client
                // distributor admin = /sales_branch/client
                // distributor staff =  /sales_branch/client
                // sales_branch = /client ( because sales_branch has a client so, he can also go to client user management)
                break;

            case 'facility':
                if (user_type === type) {
                    setMainRoute(`${path}vehicle_battery`);
                } else {
                    setMainRoute(`${path}vehicle_battery/${data.id}`);
                }
                // from facility management to facility list
                // so if admin admin type of user views the user management page of facilities
                // and then goes back to facility list, each user type will have different URL path
                // admin = distributor/sales_branch/client/facility/
                // distributor admin = /sales_branch/client/facility/
                // distributor staff = /sales_branch/client/facility/
                // sales_branch = /client/facility
                // client = /facility
                break;

            default:
                setMainRoute('/');
                break;
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_USER_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${urls(userType)}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SET_TABLE_PAGE_NO',
                payload: res.data.body.data.results.map(user => [user.id, user.username, user.email, user.is_admin]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_USER_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${urls(userType)}`;

        try {
            api_url += query !== '' ? `&search=${query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'QUERY_TABLE_DATA',
                query: query,
                payload: res.data.body.data.results.map(user => [user.id, user.username, user.email, user.is_admin]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getUsers(type, data) {
        setUserType(type)
        let api_url = `${Constants.GET_USER_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;

        switch (type) {
            case 'sales_branch':
                api_url += `&distributor=${data.distributor.id}&sales_branch=${data.id}`;
                break;

            case 'client':
                api_url += `&distributor=${data.distributor.id}&sales_branch=${data.sales_branch.id}&client=${data.id}`;
                break;

            case 'facility':
                api_url += `&distributor=${data.distributor.id}&sales_branch=${data.sales_branch.id}&client=${data.client.id}&facility=${data.id}`;
                break;

            default:
                api_url += `&distributor=${data.id}`;
                break;
        }

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'GET_USERS',
                payload: res.data.body.data.results.map(user => [user.id, user.username, user.email, user.is_admin]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function addUser(type, data) {
        const user = { ...data, ...parents(type) };

        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_USER_URL, user, config);

            dispatch({
                type: 'ADD_USER',
                payload: [data.id, data.username, data.email, false],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function deleteUser(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_USER_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_USER',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'USER_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_USER_ERROR'
        });
    }

    function cleanSlate() {
        dispatch({
            type: 'CLEAR_USER_STATE'
        });
    }

    return (
        <UserContext.Provider value={{
            parent: state.parent,
            users: state.users,
            editUser: state.editUser,
            error: state.error,
            loading: state.loading,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            getPath, path,
            mainRoute, setMainRoute,
            username, setUsername,
            email, setEmail,
            confirmEmail, setConfirmEmail,
            subEmail, setSubEmail,
            password, setPassword,
            confirmPass, setConfirmPass,
            isAdmin, setAsAdmin,
            remarks, setRemarks,
            sortData,
            queryData,
            changeNumberOfRows,
            changePageNumber,
            viewParent,
            viewUser,
            updateAccount,
            addUser,
            updateUser,
            deleteUser,
            getUsers,
            clearErrors,
            cleanSlate
        }}>
            {children}
        </UserContext.Provider>
    );
};