export default (state, action) => {
    switch (action.type) {
        case 'LOAD_ALERT_MANAGEMENT_PERMISSION':
            return {
                ...state,
                action: action.payload
            }

        case 'CHANGE_MODE':
            return {
                ...state,
                mode: action.payload
            }

        case 'GET_ALERT_DEST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                distributor: action.distributor,
                sales_branch: action.sales_branch,
                client: action.client,
                facility: action.facility,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'ADD_ALERT_DEST':
            return {
                ...state,
                loading: false,
                alert: [...state.alert, action.payload],
                success: action.success,
                error: action.error
            }

        case 'DELETE_ALERT_DEST':
            return {
                ...state,
                alert: state.alert.filter(b => b[0] !== action.payload)
            }

        case 'QUERY_TABLE_DATA_ALERT_DEST':
            return {
                ...state,
                query: action.query,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_ALERT_DEST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS_ALERT_DEST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO_ALERT_DEST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'ALERT_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_ALERT_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}