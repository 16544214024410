import React, { createContext, useReducer, useState } from 'react';
import AlertReducer from './AlertReducer';
import * as Constants from '../../Constants';
import moment from 'moment/moment'

const initialState = {
    distributor: {},
    sales_branch: {},
    client: {},
    facility: {},
    loading: false,
    alert: [],
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'created_at',
    sortOrder: 'desc',
    success: false,
    action: [{}],
    error: [{}],
    minVol: true,
    maxVol: true,
    maxTmp: true,
    liquid: true,
    remain: true,
    client_id: '',
    facility_id: '',
    battery_no: '',
    start_date: '',
    end_date: ''
};

export const AlertContext = createContext(initialState);

export const AlertProvider = ({ children }) => {

    const [path, setPath] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    function getPayload(result) {
        let payload_data = [];

        payload_data = result.map((al, index) =>
            [al.id,
            index + 1,
            al.battery.battery_no,
            moment(al.created_at).format('YYYY年MM月DD日 HH:mm:ss'),
            al.alert_type.map((at) => at.title + ',' + at.target_time).join('\n'),
            al.alert_type.map((at) => at.title === '液面水位' ? '---' : at.value).join('\n'),
            al.client.name,
            al.facility.name
            ]);

        return payload_data;
    }

    async function getAlertList() {
        let api_url = `${Constants.GET_ALERT_LIST_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
        api_url += `${state.client_id ? `&client=${state.client_id}` : ''}${state.facility_id ? `&facility=${state.facility_id}` : ''}`;
        api_url += state.battery_no !== '' ? `&search=${state.battery_no}` : '';
        api_url += state.start_date !== '' && state.start_date !== null ? `&start_date=${state.start_date.format('YYYY-MM-DD')}` : '';
        api_url += state.end_date !== '' && state.end_date !== null ? `&end_date=${state.end_date.format('YYYY-MM-DD')}` : '';
        api_url += state.minVol ? '&alert_type=minVol' : '';
        api_url += state.maxVol ? '&alert_type=maxVol' : '';
        api_url += state.maxTmp ? '&alert_type=maxTmp' : '';
        api_url += state.liquid ? '&alert_type=liquid' : '';
        api_url += state.remain ? '&alert_type=remain' : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;

            dispatch({
                type: 'GET_ALERT_LIST',
                payload: result.map((al, index) =>
                    [al.id,
                    index + 1,
                    al.battery.battery_no,
                    moment(al.created_at).format('YYYY年MM月DD日 HH:mm:ss'),
                    al.alert_type.map((at) => at.title + ',' + at.target_time).join('\n'),
                    al.alert_type.map((at) => at.title === '液面水位' ? '---' : at.value).join('\n'),
                    al.client.name,
                    al.facility.name,
                    ]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
            });

        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(client_id = '', facility_id = '', battery_no = '', start_date = '', end_date = '',
        minVol = true, maxVol = true, maxTmp = true, liquid = true, remain = true) {

        let api_url = `${Constants.GET_ALERT_LIST_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
        api_url += `${client_id ? `&client=${client_id}` : ''}${facility_id ? `&facility=${facility_id}` : ''}`;
        api_url += battery_no !== '' ? `&search=${battery_no}` : '';
        api_url += start_date !== '' && start_date !== null ? `&start_date=${start_date.format('YYYY-MM-DD')}` : '';
        api_url += end_date !== '' && end_date !== null ? `&end_date=${end_date.format('YYYY-MM-DD')}` : '';
        api_url += minVol ? '&alert_type=minVol' : '';
        api_url += maxVol ? '&alert_type=maxVol' : '';
        api_url += maxTmp ? '&alert_type=maxTmp' : '';
        api_url += liquid ? '&alert_type=liquid' : '';
        api_url += remain ? '&alert_type=remain' : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `QUERY_TABLE_DATA_ALERT_LIST`,
                query: state.query,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                client_id,
                facility_id,
                battery_no,
                start_date,
                end_date,
                minVol,
                maxVol,
                maxTmp,
                liquid,
                remain
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_ALERT_LIST_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}`;
        api_url += `${state.client_id ? `&client=${state.client_id}` : ''}${state.facility_id ? `&facility=${state.facility_id}` : ''}`;
        api_url += state.battery_no !== '' ? `&search=${state.battery_no}` : '';
        api_url += state.start_date !== '' && state.start_date !== null ? `&start_date=${state.start_date.format('YYYY-MM-DD')}` : '';
        api_url += state.end_date !== '' && state.end_date !== null ? `&end_date=${state.end_date.format('YYYY-MM-DD')}` : '';
        api_url += state.minVol ? '&alert_type=minVol' : '';
        api_url += state.maxVol ? '&alert_type=maxVol' : '';
        api_url += state.maxTmp ? '&alert_type=maxTmp' : '';
        api_url += state.liquid ? '&alert_type=liquid' : '';
        api_url += state.remain ? '&alert_type=remain' : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SORT_TABLE_ALERT_LIST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_ALERT_LIST_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}`;
        api_url += `${state.client_id ? `&client=${state.client_id}` : ''}${state.facility_id ? `&facility=${state.facility_id}` : ''}`;
        api_url += state.battery_no !== '' ? `&search=${state.battery_no}` : '';
        api_url += state.start_date !== '' && state.start_date !== null ? `&start_date=${state.start_date.format('YYYY-MM-DD')}` : '';
        api_url += state.end_date !== '' && state.end_date !== null ? `&end_date=${state.end_date.format('YYYY-MM-DD')}` : '';
        api_url += state.minVol ? '&alert_type=minVol' : '';
        api_url += state.maxVol ? '&alert_type=maxVol' : '';
        api_url += state.maxTmp ? '&alert_type=maxTmp' : '';
        api_url += state.liquid ? '&alert_type=liquid' : '';
        api_url += state.remain ? '&alert_type=remain' : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `CHANGE_TABLE_NUM_ROWS_ALERT_LIST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_ALERT_LIST_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
            api_url += `${state.client_id ? `&client=${state.client_id}` : ''}${state.facility_id ? `&facility=${state.facility_id}` : ''}`;
            api_url += state.battery_no !== '' ? `&search=${state.battery_no}` : '';
            api_url += state.start_date !== '' && state.start_date !== null ? `&start_date=${state.start_date.format('YYYY-MM-DD')}` : '';
            api_url += state.end_date !== '' && state.end_date !== null ? `&end_date=${state.end_date.format('YYYY-MM-DD')}` : '';
            api_url += state.minVol ? '&alert_type=minVol' : '';
            api_url += state.maxVol ? '&alert_type=maxVol' : '';
            api_url += state.maxTmp ? '&alert_type=maxTmp' : '';
            api_url += state.liquid ? '&alert_type=liquid' : '';
            api_url += state.remain ? '&alert_type=remain' : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SET_TABLE_PAGE_NO_ALERT_LIST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_ALERT_ERROR'
        });
    }

    function generatePath() {
        let path = null;
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (user_type) {
            case 'admin':
                path = '/distributor/sales_branch/client/facility';
                break;

            case 'distributor':
                path = '/sales_branch/client/facility';
                break;

            case 'sales_branch':
                path = '/client/facility';
                break;

            case 'client':
                path = '/facility';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    return (
        <AlertContext.Provider value={{
            distributor: state.distributor,
            sales_branch: state.sales_branch,
            client: state.client,
            facility: state.facility,
            error: state.error,
            loading: state.loading,
            alert: state.alert,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            action: state.action,
            name, setName,
            email, setEmail,
            minVol: state.minVol,
            maxVol: state.maxVol,
            maxTmp: state.maxTmp,
            liquid: state.liquid,
            remain: state.remain,
            client_id: state.client_id,
            facility_id: state.facility_id,
            query: state.query,
            start_date: state.start_date,
            end_date: state.end_date,
            battery_no: state.battery_no,
            getAlertList,
            generatePath, path,
            queryData, sortData,
            changeNumberOfRows,
            changePageNumber,
            clearErrors
        }}>
            {children}
        </AlertContext.Provider>
    );
};