export default (state, action) => {
    switch (action.type) {

        case 'CHANGE_MODE':
            return {
                ...state,
                mode: action.payload,
                base_url: action.base_url
            }

        case 'GET_LIQUID_TEMPERATURE_COEFFICIENT_LIST':
            return {
                ...state,
                loading: false,
                liquidTempCoe: action.payload,
                next: action.next,
                previous: action.previous,
                sortName: action.sortName,
                count: action.count
            }

        case 'GET_LIQUID_TEMPERATURE_COMPENSATION_LIST':
            return {
                ...state,
                loading: false,
                liquidTempComp: action.payload,
                next: action.next,
                previous: action.previous,
                sortName: action.sortName,
                count: action.count
            }

        case 'GET_DISCHARGE_COEFFICIENT_LIST':
            return {
                ...state,
                loading: false,
                dischargeCoe: action.payload,
                next: action.next,
                previous: action.previous,
                sortName: action.sortName,
                count: action.count
            }

        case 'GET_DISCHARGE_CHARACTERISTIC_LIST':
            return {
                ...state,
                loading: false,
                dischargeChar: action.payload,
                next: action.next,
                previous: action.previous,
                sortName: action.sortName,
                count: action.count
            }

        case 'GET_BATTERY_TYPE_NAME_LIST':
            return {
                ...state,
                loading: false,
                batteryType: action.payload,
                next: action.next,
                previous: action.previous,
                sortName: action.sortName,
                count: action.count
            }

        case 'QUERY_TABLE_DATA_TEMPCOE':
            return {
                ...state,
                query: action.query,
                liquidTempCoe: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'QUERY_TABLE_DATA_TEMPCOMP':
            return {
                ...state,
                query: action.query,
                liquidTempComp: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'QUERY_TABLE_DATA_DISCCOE':
            return {
                ...state,
                query: action.query,
                dischargeCoe: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'QUERY_TABLE_DATA_DISCCHAR':
            return {
                ...state,
                query: action.query,
                dischargeChar: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'QUERY_TABLE_DATA_BTYPE':
            return {
                ...state,
                query: action.query,
                batteryType: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_DATA_TEMPCOE':
            return {
                ...state,
                loading: false,
                liquidTempCoe: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_DATA_TEMPCOMP':
            return {
                ...state,
                loading: false,
                liquidTempComp: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_DATA_DISCCOE':
            return {
                ...state,
                loading: false,
                dischargeCoe: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_DATA_DISCCHAR':
            return {
                ...state,
                loading: false,
                dischargeChar: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_DATA_BTYPE':
            return {
                ...state,
                loading: false,
                batteryType: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS_TEMPCOE':
            return {
                ...state,
                loading: false,
                liquidTempCoe: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'CHANGE_TABLE_NUM_ROWS_TEMPCOMP':
            return {
                ...state,
                loading: false,
                liquidTempComp: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'CHANGE_TABLE_NUM_ROWS_DISCCOE':
            return {
                ...state,
                loading: false,
                dischargeCoe: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'CHANGE_TABLE_NUM_ROWS_DISCCHAR':
            return {
                ...state,
                loading: false,
                dischargeChar: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'CHANGE_TABLE_NUM_ROWS_BTYPE':
            return {
                ...state,
                loading: false,
                batteryType: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO_TEMPCOE':
            return {
                ...state,
                loading: false,
                liquidTempCoe: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SET_TABLE_PAGE_NO_TEMPCOMP':
            return {
                ...state,
                loading: false,
                liquidTempComp: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SET_TABLE_PAGE_NO_DISCCOE':
            return {
                ...state,
                loading: false,
                dischargeCoe: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SET_TABLE_PAGE_NO_DISCCHAR':
            return {
                ...state,
                loading: false,
                dischargeChar: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'SET_TABLE_PAGE_NO_BTYPE':
            return {
                ...state,
                loading: false,
                batteryType: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'DELETE_TEMPCOE':
            return {
                ...state,
                liquidTempCoe: state.liquidTempCoe.filter(tc => tc[0] !== action.payload)
            }

        case 'DELETE_TEMPCOMP':
            return {
                ...state,
                liquidTempComp: state.liquidTempComp.filter(tc => tc[0] !== action.payload)
            }

        case 'DELETE_DISCCOE':
            return {
                ...state,
                dischargeCoe: state.dischargeCoe.filter(dc => dc[0] !== action.payload)
            }

        case 'DELETE_DISCCHAR':
            return {
                ...state,
                dischargeChar: state.dischargeChar.filter(dc => dc[0] !== action.payload)
            }

        case 'DELETE_BTYPE':
            return {
                ...state,
                batteryType: state.batteryType.filter(bt => bt[0] !== action.payload)
            }

        case 'UPDATE_BTYPE':
            return {
                ...state,
                loading: false,
                batteryType: state.batteryType.map(bt => {
                    return [bt[0], bt[1], bt[0] === action.payload.id ? action.payload.name : bt[2], null, null, null, null, null];
                }),
            }

        case 'ADD_BTYPE':
            return {
                ...state,
                loading: false,
                batteryType: [...state.batteryType , [action.payload[0], ++state.batteryType.length , action.payload[2], null, null, null, null, null]],
                success: action.success,
                error: action.error
            }

        case 'BATTERY_TYPE_PROP_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_BATTERY_TYPE_PROP_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}