export default (state, action) => {
    switch (action.type) {
        case 'GET_BATTERY_LIST':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SET_QUERY':
            return {
                ...state,
                query: action.payload,
            }

        case 'QUERY_TABLE_DATA_BATTERY_LIST':
            return {
                ...state,
                query: action.query,
                page: action.page,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'SORT_TABLE_BATTERY_LIST':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS_BATTERY_LIST':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }


        case 'SET_TABLE_PAGE_NO_BATTERY_LIST':
            return {
                ...state,
                loading: false,
                battery: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'DELETE_BATTERY':
            return {
                ...state,
                battery: state.battery.filter(b => b[0] !== action.payload)
            }

        case 'BATTERY_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_BATTERY_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}