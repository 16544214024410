import React, { createContext, useReducer, useState } from 'react';
import MasterReducer from './MasterPageReducer';
import * as Constants from "../../Constants";

const initialState = {
    mode: null,
    batteryInfo: [], // battery_product
    chargerInfo: [], // battery_charger
    batteryTypes: [], // battery_type
    chargerTypes: [], // charger_type
    quicks: [ // quick (static)
        { id: 0, name: '普通充電' }, // 0
        { id: 1, name: '急速充電' }  // 1
    ],
    loading: false,
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'id',
    sortOrder: 'asc',
    success: false,
    action: [{}],
    error: [{}]
};

export const MasterPageContext = createContext(initialState);

export const MasterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(MasterReducer, initialState);

    /* Basic Hooks */
    const [path, setPath] = useState('/');
    const [route, setRoute] = useState('/');

    /* Battery data hooks */
    const [id, setID] = useState(null);
    const [model, setModel] = useState('');
    const [maker, setMaker] = useState('');
    const [type, setType] = useState('');
    const [charger_type, setChargerType] = useState('');
    const [chargerTypeName, setChargerTypeName] = useState('');
    const [quick, setQuick] = useState('');
    const [quickName, setQuickName] = useState('');
    const [nominal_voltage, setNominalVoltage] = useState('');
    const [nominal_capacity, setNominalCapacity] = useState('');
    const [efficiency, setEfficiency] = useState('');
    const [v_cell, setVCell] = useState('');
    const [n_cell, setNCell] = useState('');
    const [battery_type, setBatteryType] = useState(null);
    const [batteryTypeName, setBatteryTypeName] = useState('');
    const [remarks, setRemarks] = useState('');

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };
    const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;
    const granular_permissions = localStorage.getItem('auth') === null ? [] : JSON.parse(localStorage.getItem('auth')).user_permissions;

    const actions = {
        // Battery Product
        createBatteryProd: can('battery_product.add_batteryproduct'),
        updateBatteryProd: can('battery_product.change_batteryproduct'),
        deleteBatteryProd: can('battery_product.delete_batteryproduct'),
        viewBatteryProd: can('battery_product.view_batteryproduct'),
        listBatteryProd: can('battery_product.list_batteryproduct'),
        // Battery charger
        createCharger: can('battery_charger.add_batterycharger'),
        updateCharger: can('battery_charger.change_batterycharger'),
        deleteCharger: can('battery_charger.delete_batterycharger'),
        viewCharger: can('battery_charger.view_batterycharger'),
        listCharger: can('battery_charger.list_batterycharger'),
        // Liquid temperature coefficient
        createTempCoe: can('temperature_coefficient.add_temperaturecoefficient'),
        updateTempCoe: can('temperature_coefficient.change_temperaturecoefficient'),
        deleteTempCoe: can('temperature_coefficient.delete_temperaturecoefficient'),
        viewTempCoe: can('temperature_coefficient.view_temperaturecoefficient'),
        listTempCoe: can('temperature_coefficient.list_temperaturecoefficient'),
        importTempCoe: can('temperature_coefficient.import_temperaturecoefficient'),
        // Liquid temperature compensation
        createTempComp: can('temperature_compensation.add_temperaturecompensation'),
        updateTempComp: can('temperature_compensation.change_temperaturecompensation'),
        deleteTempComp: can('temperature_compensation.delete_temperaturecompensation'),
        viewTempComp: can('temperature_compensation.view_temperaturecompensation'),
        listTempComp: can('temperature_compensation.list_temperaturecompensation'),
        importTempComp: can('temperature_compensation.import_temperaturecompensation'),
        // Discharge coefficient
        createDiscCoe: can('discharge_coefficient.add_dischargecoefficient'),
        updateDiscCoe: can('discharge_coefficient.change_dischargecoefficient'),
        deleteDiscCoe: can('discharge_coefficient.delete_dischargecoefficient'),
        viewDiscCoe: can('discharge_coefficient.view_dischargecoefficient'),
        listDiscCoe: can('discharge_coefficient.list_dischargecoefficient'),
        importDiscCoe: can('discharge_coefficient.import_dischargecoefficient'),
        // Discharge characteristics
        createDiscChar: can('discharge_characteristic.add_dischargecharacteristic'),
        updateDiscChar: can('discharge_characteristic.change_dischargecharacteristic'),
        deleteDiscChar: can('discharge_characteristic.delete_dischargecharacteristic'),
        viewDiscChar: can('discharge_characteristic.view_dischargecharacteristic'),
        listDiscChar: can('discharge_characteristic.list_dischargecharacteristic'),
        importDiscChar: can('discharge_characteristic.import_dischargecharacteristic'),
        // battery type
        createBatteryType: can('battery_type.add_batterytype'),
        updateBatteryType: can('battery_type.change_batterytype'),
        deleteBatteryType: can('battery_type.delete_batterytype'),
        viewBatteryType: can('battery_type.view_batterytype'),
        listBatteryType: can('battery_type.list_batterytype')
    }

    async function loadPermissions() {
        if (user_type === 'admin') {
            dispatch({
                type: 'LOAD_MASTER_PAGE_PERMISSION',
                payload: actions
            });
        } else {
            document.location.href = '/';
        }
    }

    function changeMode(mode) {
        setRoute(`/master_management/product/${mode}`);

        dispatch({
            type: 'CHANGE_MODE',
            payload: mode
        });
    }

    async function viewData(id, mode) {
        let api_url = `${mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            setID(data.id);
            setModel(data.model);
            setMaker(data.maker);
            setEfficiency(data.efficiency);
            setRemarks(data.remarks);

            if (mode === 'battery') {
                setVCell(data.v_cell);
                setNCell(data.n_cell);
                setBatteryType(data.battery_type.id);
                setBatteryTypeName(data.battery_type.name);
                setNominalVoltage(data.nominal_voltage);
                setNominalCapacity(data.nominal_capacity);
            } else {
                setType(data.type);
                setChargerType(data.charger_type.id);
                setChargerTypeName(data.charger_type.name);
                setQuick(data.quick);
                setQuickName(state.quicks.find(e => e.id == data.quick).name);
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: state.mode === 'battery' ? 'CHANGE_TABLE_NUM_ROWS_BATTERY_PRODUCT' : 'CHANGE_TABLE_NUM_ROWS_BATTERY_CHARGER',
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });

        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: state.mode === 'battery' ? 'SET_TABLE_PAGE_NO_BATTERY_PRODUCT' : 'SET_TABLE_PAGE_NO_BATTERY_CHARGER',
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });

        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
        api_url += query !== '' ? `&search=${query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: state.mode === 'battery' ? 'QUERY_TABLE_DATA_BATTERY_PRODUCT' : 'QUERY_TABLE_DATA_BATTERY_CHARGER',
                query: query,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0
            });
        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function sortData(name, direction) {
        let api_url = `${state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: state.mode === 'battery' ? 'SORT_TABLE_BATTERY_PRODUCT' : 'SORT_TABLE_BATTERY_CHARGER',
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getData(mode = '', sort = 'id') {
        let api_url = `${mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${sort}&page_size=${state.rowsPerPage}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = [];

            if (mode === 'battery') {
                payload_data = result.map((battery, index) =>
                    [battery.id, index + 1, battery.model, battery.maker, battery.battery_type.name, battery.nominal_voltage, battery.nominal_capacity, battery.efficiency]);
            } else {
                payload_data = result.map((charger, index) =>
                    [charger.id, index + 1,
                    charger.maker,
                    charger.charger_type.name,
                    state.quicks.find(e => e.id == charger.quick).name,
                    charger.efficiency]);
            }

            dispatch({
                mode: mode,
                type: mode === 'battery' ? 'GET_BATTERY_PRODUCT_LIST' : 'GET_BATTERY_CHARGER_LIST',
                payload: payload_data,
                sortName: sort,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function getBatteryTypeList() {
        let api_url = `${Constants.GET_BATTERY_TYPE_URL}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'GET_BATTERY_TYPE_LIST',
                payload: res.data.body.data.results
            });
        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function getChargerTypeList() {
        let api_url = `${Constants.GET_CHARGER_TYPE_URL}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'GET_CHARGER_TYPE_LIST',
                payload: res.data.body.data.results
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    function getPayload(result) {
        let payload_data = [];

        if (state.mode === 'battery') {
            payload_data = result.map((battery, index) =>
                [battery.id, index + 1, battery.model, battery.maker, battery.battery_type.name, battery.nominal_voltage, battery.nominal_capacity, battery.efficiency]);
        } else {
            payload_data = result.map((charger, index) =>
                [charger.id, index + 1,
                charger.maker,
                charger.charger_type.name,
                state.quicks.find(e => e.id == charger.quick).name,
                charger.efficiency]);
        }

        return payload_data;
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_MASTER_PAGE_ERROR'
        });
    }

    async function addBatteryData(data) {
        try {
            let res = await Constants.API_BASE_URL.post(state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL, data, config);

            dispatch({
                type: state.mode === 'battery' ? 'ADD_BATTERY_PRODUCT' : 'ADD_BATTERY_CHARGER',
                payload: [],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function updateBatteryData(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}${record.id}`, record, config);
            console.log('record', record);

            dispatch({
                type: state.mode === 'battery' ? 'UPDATE_BATTERY_PRODUCT' : 'UPDATE_BATTERY_CHARGER',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function deleteBatteryData(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${state.mode === 'battery' ? Constants.GET_BATTERY_PRODUCT_URL : Constants.GET_BATTERY_CHARGER_URL}${record.id}`, config);

            dispatch({
                type: state.mode === 'battery' ? 'DELETE_BATTERY_PRODUCT' : 'DELETE_BATTERY_CHARGER',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'MASTER_PAGE_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    function generatePath() {
        let path = null;

        switch (user_type) {
            case 'admin':
                path = '/distributor/';
                break;

            case 'distributor':
                path = '/sales_branch/';
                break;

            case 'sales_branch':
                path = '/client/';
                break;

            case 'client':
                path = '/facility/';
                break;

            case 'facility':
                path = '/vehicle_battery';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    function can(action) {
        if (granular_permissions) {
            return granular_permissions.includes(action);
        }

        localStorage.clear();
        window.location.reload();
    }

    return (
        <MasterPageContext.Provider value={{
            mode: state.mode,
            batteryInfo: state.batteryInfo,
            batteryTypes: state.batteryTypes,
            chargerInfo: state.chargerInfo,
            chargerTypes: state.chargerTypes,
            quicks: state.quicks,
            error: state.error,
            loading: state.loading,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            action: state.action,
            id, setID,
            model, setModel,
            maker, setMaker,
            type, setType,
            charger_type, setChargerType, chargerTypeName,
            quick, setQuick,
            quickName, setQuickName,
            nominal_voltage, setNominalVoltage,
            nominal_capacity, setNominalCapacity,
            efficiency, setEfficiency,
            v_cell, setVCell,
            n_cell, setNCell,
            battery_type, setBatteryType, batteryTypeName,
            remarks, setRemarks,
            changeMode, loadPermissions,
            addBatteryData, updateBatteryData, deleteBatteryData,
            generatePath, path, route,
            getBatteryTypeList,
            getChargerTypeList,
            viewData,
            queryData,
            sortData,
            getData,
            changeNumberOfRows,
            changePageNumber,
            clearErrors
        }}>
            {children}
        </MasterPageContext.Provider>
    );
};