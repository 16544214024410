export default (state, action) => {
    switch (action.type) {
        case 'GET_SALES_BRANCHES':
            return {
                ...state,
                loading: false,
                sales_branches: action.payload,
                distributor: action.distributor,
                next: action.next,
                previous: action.previous,
                count: action.count,
                action: action.actions
            }

        case 'VIEW_SALES_BRANCH':
            return {
                ...state,
                loading: false,
                editData: action.payload
            }

        case 'SORT_TABLE':
            return {
                ...state,
                loading: false,
                sales_branches: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS':
            return {
                ...state,
                loading: false,
                sales_branches: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO':
            return {
                ...state,
                loading: false,
                sales_branches: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'QUERY_TABLE_DATA':
            return {
                ...state,
                query: action.query,
                sales_branches: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'ADD_SALES_BRANCH':
            return {
                ...state,
                loading: false,
                sales_branches: [...state.sales_branches, action.payload],
                success: action.success,
                error: action.error
            }

        case 'DELETE_SALES_BRANCH':
            return {
                ...state,
                sales_branches: state.sales_branches.filter(sales_branch => sales_branch[0] !== action.payload)
            }

        case 'UPDATE_SALES_BRANCH':
            return {
                ...state,
                loading: false,
                sales_branches: [...state.sales_branches, action.payload],
            }

        case 'SALES_BRANCH_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_SALES_BRANCH_ERROR':
            return {
                ...state,
                error: []
            }

        case 'CLEAR_SALES_BRANCHES':
            return {
                ...state,
                sales_branches: []
            }

        default:
            return state;
    }
}