export default (state, action) => {
    switch (action.type) {
        case 'LOAD_ALERT_MANAGEMENT_PERMISSION':
            return {
                ...state,
                action: action.payload
            }

        case 'GET_ALERT_LIST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'QUERY_TABLE_DATA_ALERT_LIST':
            return {
                ...state,
                query: action.query,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                client_id: action.client_id,
                facility_id: action.facility_id,
                battery_no: action.battery_no,
                start_date: action.start_date,
                end_date: action.end_date,
                minVol: action.minVol,
                maxVol: action.maxVol,
                maxTmp: action.maxVol,
                liquid: action.liquid,
                remain: action.remain
            }

        case 'SORT_TABLE_ALERT_LIST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                sortName: action.sortName,
                sortOrder: action.sortOrder,
                next: action.next,
                previous: action.previous,
                count: action.count
            }

        case 'CHANGE_TABLE_NUM_ROWS_ALERT_LIST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page,
                rowsPerPage: action.rowsPerPage
            }

        case 'SET_TABLE_PAGE_NO_ALERT_LIST':
            return {
                ...state,
                loading: false,
                alert: action.payload,
                next: action.next,
                previous: action.previous,
                count: action.count,
                page: action.page
            }

        case 'ALERT_ERROR':
            return {
                ...state,
                error: action.payload
            }

        case 'CLEAR_ALERT_ERROR':
            return {
                ...state,
                error: []
            }

        default:
            return state;
    }
}