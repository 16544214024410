import axios from 'axios';
import { createMuiTheme } from '@material-ui/core/styles';

// Makes axios send cookies in its requests automatically.
// This is used by server to handle session timeouts and
// token invalidation.
axios.defaults.withCredentials = true;

// Constant URLs
export const API_BASE_URL = axios.create({ baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/v1` });
export const AUTH_LOGIN_URL = '/auth/login/';
export const AUTH_LOGOUT_URL = '/auth/logout/';
export const GET_USER_URL = '/user/';
export const GET_DISTRIBUTOR_URL = '/distributor/';
export const GET_SALES_BRANCH_URL = '/sales_branch/';
export const GET_CLIENT_URL = '/client/';
export const GET_FAC_DEPT_URL = '/facility/';
export const GET_ALERT_DEST_URL = '/alert_destination/';
export const GET_ALERT_LIST_URL = '/alert/';
export const GET_VEHICLE_URL = '/vehicle/';
export const GET_BATTERY_URL = '/battery/';
export const GET_SENSOR_URL = '/sensor/';
export const GET_GRAPH_URL = '/report/graph/pdf';
export const GET_REPORT1_URL = '/report/{client_id}/report1';
export const GET_REPORT2_URL = '/report/{client_id}/report2';
export const GET_REPORT3_URL = '/report/report3';
export const GET_REPORT4_URL = '/report/report4';
export const GET_BATTERY_PRODUCT_URL = '/battery_product/';
export const GET_BATTERY_PRODUCT_MODEL_URL = '/battery_product/model/';
export const GET_BATTERY_CHARGER_URL = '/battery_charger/';
export const GET_BATTERY_TYPE_URL = '/battery_type/';
export const GET_CHARGER_TYPE_URL = '/charger_type/';
export const GET_TEMPERATURE_COEFFICIENT_URL = '/temperature_coefficient/';
export const GET_DISCHARGE_COEFFICIENT_URL = '/discharge_coefficient/';
export const GET_TEMPERATURE_COMPENSATION_URL = '/temperature_compensation/';
export const GET_DISCHARGE_CHARACTERISTIC_URL = '/discharge_characteristic/';
export const GET_FORGOT_PASS_URL = '/user/request-password-reset-mail';
export const GET_PASSWORD_RESET_COMPLETE_URL = '/user/password-reset-complete';
export const GET_PASSWORD_RESET_CONFIRM_URL = '/user/password-reset-confirm';

// MUI-Datatable settings
export const getMuiTheme = () => createMuiTheme({
    overrides: {
        MUIDataTableHeadCell: {
            root: {
                fontWeight: 'bold',
                fontSize: '12pt',
                color: '#535353',
                textAlign: 'center'
            },
            fixedHeader: {
                backgroundColor: '#f4f4f4'
            }
        }
    }
});

export const options = {
    elevation: 0,
    serverSide: true,
    filter: false,
    download: false,
    search: false,
    selectableRows: 'none',
    rowsPerPageOptions: [10, 30, 100],
    print: false,
    viewColumns: false,
    filterType: 'checkbox',
    responsive: 'standard',
    textLabels: {
        body: {
            noMatch: '該当データがありません',
        },
        pagination: {
            rowsPerPage: '表示件数',
            displayRows: '/',
        },
    }
};

// Breadcrumbs
export const LINK = {
    account: 'アカウント設定',
    add: '販売代理店を追加',
    addBattery: 'バッテリを追加',
    addBatteryCharger: '充電器製品情報を追加',
    addBatteryProd: 'バッテリ製品情報を追加',
    addClient: 'お客様を追加',
    addFacility: '施設/部署を追加',
    addSales: '営業所を追加',
    addUser: 'ユーザを追加',
    addVehicle: '⾞体を追加',
    addVehicleAndBattery: '⾞体・バッテリを追加',
    alert_list: 'アラート一覧',
    alert_management: 'アラート通知先管理',
    battery: 'バッテリ製品情報',
    bType: 'バッテリ型名称',
    car_battery: '⾞両・バッテリ⼀覧',
    charger: '充電器製品情報',
    client: 'お客様⼀覧',
    discChar: '放電特性',
    discCoe: '放電係数',
    distributor: '販売代理店⼀覧',
    edit: '販売代理店を編集',
    editBattery: 'バッテリを編集',
    editBatteryCharger: '充電器製品情報を編集',
    editBatteryProd: 'バッテリ製品情報を編集',
    editClient: 'お客様を編集',
    editFacility: '施設/部署を編集',
    editSales: '営業所を編集',
    editUser: 'ユーザを編集',
    editVehicle: '⾞体を編集',
    facility: '施設/部署⼀覧',
    master_management: 'マスタ管理',
    sales_branch: '営業所⼀覧',
    show: '販売代理店の詳細',
    showBattery: 'バッテリの詳細',
    showBatteryCharger: '充電器製品情報を閲覧',
    showBatteryProd: 'バッテリ製品情報を閲覧',
    showClient: 'お客様の詳細',
    showFacility: '施設/部署の詳細',
    showSales: '営業所の詳細',
    showUser: 'ユーザの詳細',
    showVehicle: '⾞体の詳細',
    tempCoe: '液温係数',
    tempComp: '液温補償',
    user_management: 'ユーザ管理',
    vehicle_battery: '⾞体・バッテリ⼀覧',
    all_battery: '全バッテリ一覧'
};

export const formatter = new Intl.NumberFormat('ja-JP');