import React, { createContext, useReducer, useState } from 'react';
import BatteryTypePropReducer from './BatteryTypePropReducer';
import * as Constants from '../../Constants';

const initialState = {
    loading: false,
    mode: null,
    base_url: null,
    batteryType: [],
    liquidTempCoe: [],
    liquidTempComp: [],
    dischargeCoe: [],
    dischargeChar: [],
    xValue: {
        tempCoe: '液温係数',
        tempComp: '温度補償係数',
        discCoe: '取り出し倍率',
        discChar: '放電電圧'
    },
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'id',
    sortOrder: 'asc',
    success: false,
    action: [{}],
    error: [{}]
};

export const BatteryTypePropContext = createContext(initialState);

export const BatteryTypePropProvider = ({ children }) => {
    const [state, dispatch] = useReducer(BatteryTypePropReducer, initialState);

    /* Basic Hooks */
    const [route, setRoute] = useState('/');

    /* Battery type data hooks */
    const [id, setID] = useState(null);
    const [name, setName] = useState('');
    const [x_value, setXValue] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [depth_disc, setDepthDisc] = useState(null);
    const [rate_disc, setRateDisc] = useState(null);
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    function changeMode(mode) {
        setRoute(`/master_management/battery/${mode}`);

        dispatch({
            type: 'CHANGE_MODE',
            payload: mode,
            base_url: getBaseURL(mode),
        });
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${state.base_url}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `CHANGE_TABLE_NUM_ROWS_${state.mode.toUpperCase()}`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });

        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${state.base_url}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SET_TABLE_PAGE_NO_${state.mode.toUpperCase()}`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });

        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${state.base_url}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}`;
        api_url += query !== '' ? `&search=${query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `QUERY_TABLE_DATA_${state.mode.toUpperCase()}`,
                query: query,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function sortData(name, direction) {
        let api_url = `${state.base_url}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SORT_TABLE_DATA_${state.mode.toUpperCase()}`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function getData(mode = null, sort = 'id') {
        let api_url = `${getBaseURL(mode)}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${sort}&page_size=${state.rowsPerPage}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result, mode);

            dispatch({
                type: getReducerType(mode),
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                sortName: sort,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function deleteData(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${getBaseURL()}${record.id}`, config);

            dispatch({
                type: `DELETE_${state.mode.toUpperCase()}`,
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function importCSV() {
        const fd = new FormData();
        fd.append('file', file, file.name);

        let res = await Constants.API_BASE_URL.post(getBaseURL() + 'import', fd,
            {
                ...config,
                onUploadProgress: progressEvent =>
                    setProgress(Math.round((progressEvent.loaded / progressEvent.total * 100)))
            });

        try {
            return Promise.resolve(res);
        } catch (error) {
            return Promise.reject(error.response);
        }
    }

    function getReducerType(mode) {
        mode = mode === null ? state.mode : mode;
        let type = null;

        switch (mode) {
            case 'tempCoe':
                type = 'GET_LIQUID_TEMPERATURE_COEFFICIENT_LIST';
                break;

            case 'tempComp':
                type = 'GET_LIQUID_TEMPERATURE_COMPENSATION_LIST';
                break;

            case 'discCoe':
                type = 'GET_DISCHARGE_COEFFICIENT_LIST';
                break;

            case 'discChar':
                type = 'GET_DISCHARGE_CHARACTERISTIC_LIST';
                break;

            default:
                type = 'GET_BATTERY_TYPE_NAME_LIST';
                break;
        }

        return type;
    }

    function getPayload(data, mode = null) {
        let payload_data = [];
        mode = mode === null ? state.mode : mode;

        switch (mode) {
            case 'tempCoe':
            case 'tempComp':
                payload_data = data.map((tc, index) =>
                    [tc.id, index + 1, null, tc.battery_type.name, tc.temperature, null, null, tc.x_value]);
                break;

            case 'discCoe':
            case 'discChar':
                payload_data = data.map((dc, index) =>
                    [
                        dc.id,
                        index + 1,
                        null,
                        dc.battery_type.name,
                        null,
                        mode === 'discCoe' ? dc.depth_disc : '',
                        mode === 'discChar' ? dc.rate_disc : '',
                        dc.x_value
                    ]);
                break;

            default:
                payload_data = data.map((bt, index) =>
                    [bt.id, index + 1, bt.name, null, null, null, null, null]);
                break;
        }

        return payload_data;
    }

    async function viewBatteryType(id) {
        let api_url = `${Constants.GET_BATTERY_TYPE_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data;

            setName(data.name);
        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function addBatteryType(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_BATTERY_TYPE_URL, data, config);

            dispatch({
                type: 'ADD_BTYPE',
                payload: [res.data.body.data.id, null, res.data.body.data.name, null, null, null, null, null],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function updateBatteryType(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_BATTERY_TYPE_URL}${record.id}`, record, config);

            dispatch({
                type: 'UPDATE_BTYPE',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'BATTERY_TYPE_PROP_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    function getBaseURL(mode = null) {
        mode = mode === null ? state.mode : mode;
        let url = null;

        switch (mode) {
            case 'tempCoe':
                url = Constants.GET_TEMPERATURE_COEFFICIENT_URL;
                break;

            case 'tempComp':
                url = Constants.GET_TEMPERATURE_COMPENSATION_URL;
                break;

            case 'discCoe':
                url = Constants.GET_DISCHARGE_COEFFICIENT_URL;
                break;

            case 'discChar':
                url = Constants.GET_DISCHARGE_CHARACTERISTIC_URL;
                break;

            default:
                url = Constants.GET_BATTERY_TYPE_URL;
                break;
        }

        return url;
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_BATTERY_TYPE_PROP_ERROR'
        });
    }

    return (
        <BatteryTypePropContext.Provider value={{
            mode: state.mode,
            base_url: state.base_url,
            batteryType: state.batteryType,
            liquidTempCoe: state.liquidTempCoe,
            liquidTempComp: state.liquidTempComp,
            dischargeCoe: state.dischargeCoe,
            dischargeChar: state.dischargeChar,
            xValue: state.xValue,
            error: state.error,
            loading: state.loading,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            action: state.action,
            route,
            id, setID,
            name, setName,
            x_value, setXValue,
            temperature, setTemperature,
            depth_disc, setDepthDisc,
            rate_disc, setRateDisc,
            setProgress, progress,
            file, setFile, importCSV,
            changeMode,
            viewBatteryType,
            addBatteryType,
            updateBatteryType,
            deleteData,
            getData,
            sortData,
            queryData,
            changeNumberOfRows,
            changePageNumber,
            clearErrors
        }}>
            {children}
        </BatteryTypePropContext.Provider>
    );
};