import React, { createContext, useReducer, useState } from 'react';
import AlertDestinationReducer from './AlertDestinationReducer';
import * as Constants from '../../Constants';
import moment from 'moment/moment'

const initialState = {
    distributor: {},
    sales_branch: {},
    client: {},
    facility: {},
    mode: null,
    loading: false,
    alert: [],
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'id',
    sortOrder: 'asc',
    success: false,
    action: [{}],
    error: [{}]
};

export const AlertDestinationContext = createContext(initialState);

export const AlertDestinationProvider = ({ children }) => {

    const [path, setPath] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [state, dispatch] = useReducer(AlertDestinationReducer, initialState);

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    function changeMode(mode) {
        dispatch({
            type: 'CHANGE_MODE',
            payload: mode
        });
    }

    function getPayload(result) {
        let payload_data = [];

        payload_data = result.map((ad) =>
            [ad.id, ad.name, ad.email, ad.remarks]);

        return payload_data;
    }

    async function addAlertDestination(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_ALERT_DEST_URL, data, config);

            dispatch({
                type: 'ADD_ALERT_DEST',
                payload: [res.data.body.data.id, data.name, data.email, res.data.body.data.remarks],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function getAlertDestination(id = null) {
        let api_url = `${Constants.GET_ALERT_DEST_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${id ? `&facility=${id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;

            dispatch({
                type: 'GET_ALERT_DEST',
                payload: result.map((ad) => [ad.id, ad.name, ad.email, ad.remarks]),
                facility: res.data.body.data.facility,
                client: res.data.body.data.client,
                sales_branch: res.data.body.data.sales_branch,
                distributor: res.data.body.data.distributor,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function deleteAlertDestination(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_ALERT_DEST_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_ALERT_DEST',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_ALERT_DEST_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.facility.id ? `&facility=${state.facility.id}` : ''}`;
        api_url += query !== '' ? `&search=${query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `QUERY_TABLE_DATA_ALERT_DEST`,
                query: query,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }


    async function sortData(name, direction) {
        console.log(state);
        let api_url = `${Constants.GET_ALERT_DEST_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}${state.facility ? `&facility=${state.facility.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SORT_TABLE_ALERT_DEST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_ALERT_DEST_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${state.facility ? `&facility=${state.facility.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `CHANGE_TABLE_NUM_ROWS_ALERT_DEST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_ALERT_DEST_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${state.facility ? `&facility=${state.facility.id}` : ''}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);
            const result = res.data.body.data.results;
            let payload_data = getPayload(result);

            dispatch({
                type: `SET_TABLE_PAGE_NO_ALERT_DEST`,
                payload: payload_data,
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
        } catch (error) {
            dispatch({
                type: 'ALERT_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min) + min);
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_ALERT_ERROR'
        });
    }

    function generatePath() {
        let path = null;
        const user_type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (user_type) {
            case 'admin':
                path = '/distributor/sales_branch/client/facility';
                break;

            case 'distributor':
                path = '/sales_branch/client/facility';
                break;

            case 'sales_branch':
                path = '/client/facility';
                break;

            case 'client':
                path = '/facility';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    return (
        <AlertDestinationContext.Provider value={{
            distributor: state.distributor,
            sales_branch: state.sales_branch,
            client: state.client,
            facility: state.facility,
            error: state.error,
            loading: state.loading,
            alert: state.alert,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            action: state.action,
            name, setName,
            email, setEmail,
            changeMode,
            addAlertDestination,
            getAlertDestination,
            deleteAlertDestination,
            generatePath, path,
            sortData, queryData,
            changeNumberOfRows,
            changePageNumber,
            clearErrors
        }}>
            {children}
        </AlertDestinationContext.Provider>
    );
};