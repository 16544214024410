import React, { createContext, useReducer, useState } from 'react';
import SalesReducer from './SalesReducer';
import * as Constants from '../../Constants';

const initialState = {
    sales_branches: [],
    editData: [],
    loading: true,
    next: '',
    previous: null,
    count: 0,
    page: 0,
    rowsPerPage: 10,
    query: '',
    sortName: 'name_kana',
    sortOrder: 'asc',
    success: false,
    action: [{
        view: false,
        create: false,
        delete: false,
        update: false,
        management: false
    }],
    error: [{
        name: null,
        address: null,
        name_kana: null,
        postal_code: null,
        telephone_number: null,
        sales_branch: null
    }]
};

export const SalesContext = createContext(initialState);

export const SalesProvider = ({ children }) => {
    const [companyName, setCompanyName] = useState('');
    const [kanaName, setKanaName] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [remark, setRemark] = useState('');
    const [distributor, setDistributor] = useState({});
    const [path, setPath] = useState(null);

    const [state, dispatch] = useReducer(SalesReducer, initialState);

    const granular_permissions = localStorage.getItem('auth') === null ? [] : JSON.parse(localStorage.getItem('auth')).user_permissions;

    const config = {
        headers: {
            'Accept-Language': 'ja',
            'Authorization': localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).token
        }
    };

    // User permissions for crud operations
    const actions = {
        view: can('sales_branch.view_salesbranch'),
        create: can('sales_branch.add_salesbranch'),
        delete: can('sales_branch.delete_salesbranch'),
        update: can('sales_branch.change_salesbranch'),
        management: canManageUsers(),
    }

    function clearSalesBranches() {
        dispatch({
            type: 'CLEAR_SALES_BRANCHES',
        });
    }

    async function getSalesBranches(id = null) {
        let api_url = `${Constants.GET_SALES_BRANCH_URL}?page=${state.page + 1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${id ? `&distributor=${id}` : ''}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                ...{ actions: actions },
                type: 'GET_SALES_BRANCHES',
                payload: res.data.body.data.results.map((sb, index) =>
                    [sb.id, index + 1, sb.name, sb.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });

            setDistributor(res.data.body.data.distributor);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCHES_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function sortData(name, direction) {
        let api_url = `${Constants.GET_SALES_BRANCH_URL}?page=${state.page + 1}&order_by=${direction === 'desc' ? '-' : ''}${name}&page_size=${state.rowsPerPage}${distributor ? `&distributor=${distributor.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SORT_TABLE',
                payload: res.data.body.data.results.map((sb, index) =>
                    [sb.id, index + 1, sb.name, sb.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                sortName: name,
                sortOrder: direction
            });
            setDistributor(res.data.body.data.distributor);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCHES_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changeNumberOfRows(rows) {
        let api_url = `${Constants.GET_SALES_BRANCH_URL}?page=1&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${rows}${distributor ? `&distributor=${distributor.id}` : ''}`;
        api_url += state.query !== '' ? `&search=${state.query}` : '';

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'CHANGE_TABLE_NUM_ROWS',
                payload: res.data.body.data.results.map((sb, index) =>
                    [sb.id, index + 1, sb.name, sb.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: 0,
                rowsPerPage: rows
            });
            setDistributor(res.data.body.data.distributor);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCHES_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function changePageNumber(tblPage) {
        let url = null;
        let nextPage = 0;

        if (tblPage > state.page) {
            url = state.next;
        } else {
            url = state.previous;
        }

        if (url !== null) {
            let urlExplode = new URL(url);
            nextPage = urlExplode.searchParams.get('page');
            nextPage = nextPage === null ? 1 : nextPage;
        }

        try {
            let api_url = `${Constants.GET_SALES_BRANCH_URL}?page=${nextPage}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${distributor ? `&distributor=${distributor.id}` : ''}`;
            api_url += state.query !== '' ? `&search=${state.query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'SET_TABLE_PAGE_NO',
                payload: res.data.body.data.results.map((sb, index) =>
                    [sb.id, index + 1, sb.name, sb.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record,
                page: tblPage
            });
            setDistributor(res.data.body.data.distributor);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCHES_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function queryData(query = '') {
        let api_url = `${Constants.GET_SALES_BRANCH_URL}?page=${1}&order_by=${state.sortOrder === 'desc' ? '-' : ''}${state.sortName}&page_size=${state.rowsPerPage}${distributor ? `&distributor=${distributor.id}` : ''}`;

        try {
            api_url += query !== '' ? `&search=${query}` : '';

            const res = await Constants.API_BASE_URL.get(api_url, config);

            dispatch({
                type: 'QUERY_TABLE_DATA',
                query: query,
                payload: res.data.body.data.results.map((sb, index) =>
                    [sb.id, index + 1, sb.name, sb.number_of_batteries]),
                next: res.data.body.data.links.next,
                previous: res.data.body.data.links.previous,
                count: res.data.body.data.total_record
            });
            setDistributor(res.data.body.data.distributor);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCHES_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    async function addSalesBranch(data) {
        try {
            let res = await Constants.API_BASE_URL.post(Constants.GET_SALES_BRANCH_URL, data, config);

            dispatch({
                type: 'ADD_SALES_BRANCH',
                payload: [data.id, data.name, data.number_of_batteries],
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCH_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(error.response);
        }
    }

    async function deleteSalesBranch(record) {
        try {
            const res = await Constants.API_BASE_URL.delete(`${Constants.GET_SALES_BRANCH_URL}${record.id}`, config);

            dispatch({
                type: 'DELETE_SALES_BRANCH',
                payload: record.id
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCH_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function updateSalesBranch(record) {
        try {
            const res = await Constants.API_BASE_URL.patch(`${Constants.GET_SALES_BRANCH_URL}${state.editData.id}`, record, config);

            dispatch({
                type: 'UPDATE_SALES_BRANCH',
                payload: record,
                success: res.data.details.message,
                error: []
            });

            return Promise.resolve(res);
        } catch (error) {
            dispatch({
                type: 'SALES_BRANCH_ERROR',
                payload: error.response.data.details.message
            });

            return Promise.reject(false);
        }
    }

    async function viewSalesBranch(id) {
        let api_url = `${Constants.GET_SALES_BRANCH_URL}${id}`;

        try {
            const res = await Constants.API_BASE_URL.get(api_url, config);
            const data = res.data.body.data

            dispatch({
                type: 'VIEW_SALES_BRANCH',
                payload: data
            });

            setCompanyName(data.name);
            setKanaName(data.name_kana);
            setPostalCode(data.postal_code);
            setStreetAddress(data.address);
            setPhoneNumber(data.telephone_number);
            setRemark(data.remarks);
            setDistributor(data.distributor);

        } catch (error) {
            dispatch({
                type: 'DISTRIBUTOR_ERROR',
                payload: error.response.data.details.message
            });
        }
    }

    function clearErrors() {
        dispatch({
            type: 'CLEAR_SALES_BRANCH_ERROR'
        });
    }

    // Create path generator base on user type if super admin, distributor admin or distributor staff
    // Because these types are the user types allowed to access the sales branch data and operations (e.g: add, edit, delete )
    function generatePath() {
        let path = null;
        const type = localStorage.getItem('auth') === null ? '' : JSON.parse(localStorage.getItem('auth')).user_type;

        switch (type) {
            case 'admin':
                /*
                 * /distributor/sales_branch/addSales
                 * /distributor/sales_branch/editSales/:id
                 * /distributor/sales_branch/showSales/:id
                 * /distributor/sales_branch/:id */
                path = '/distributor/sales_branch';
                break;

            case 'distributor':
                /*
                  * /sales_branch/
                  * /sales_branch/addSales
                  * /sales_branch/editSales/:id
                  * /sales_branch/showSales/:id
                */
                path = '/sales_branch';
                break;

            default:
                path = '/';
                break;
        }

        setPath(path);
    }

    function resetLocalStorage() {
        localStorage.clear();
        window.location.reload();
    }

    function can(action) {
        if (granular_permissions) {
            return granular_permissions.includes(action);
        }

        resetLocalStorage();
    }

    function canManageUsers() {
        if (granular_permissions) {
            return granular_permissions.includes('users.view_user')
                && granular_permissions.includes('users.add_user')
                && granular_permissions.includes('users.change_user')
                && granular_permissions.includes('users.delete_user');
        }

        resetLocalStorage();
    }

    return (
        <SalesContext.Provider value={{
            sales_branches: state.sales_branches,
            editData: state.editData,
            loading: state.loading,
            links: state.links,
            count: state.count,
            rowsPerPage: state.rowsPerPage,
            page: state.page,
            sortName: state.sortName,
            sortOrder: state.sortOrder,
            error: state.error,
            action: state.action,
            distributor, setDistributor,
            companyName, setCompanyName,
            kanaName, setKanaName,
            postalCode, setPostalCode,
            streetAddress, setStreetAddress,
            phoneNumber, setPhoneNumber,
            remark, setRemark,
            path, generatePath,
            sortData,
            changeNumberOfRows,
            changePageNumber,
            queryData,
            viewSalesBranch,
            addSalesBranch,
            deleteSalesBranch,
            updateSalesBranch,
            getSalesBranches,
            clearErrors,
            clearSalesBranches
        }}>
            {children}
        </SalesContext.Provider>
    );
};